import { ProductListItem } from '../models/ProductListItem';
import { WEIGHT_DIFFERENCE_TOLERANCE } from '../../shared/constants';
import { Coil } from '../models/Coil';

export class WeightUtils {
  static roundDisplay(value: number | null): number | null {
    if (value){
      return Math.round(value * 1000) / 1000;
    }
    return value;
  }

  static isEmpty(value: number | null): boolean {
    return !value && value !== 0;
  }

  static computeInputProductionWeight(coilWeightBeforeProductionInputted: number, coilWeightInputted: number): number {
    if (this.isEmpty(coilWeightBeforeProductionInputted) || this.isEmpty(coilWeightInputted)) {
      return 0;
    }
    return coilWeightBeforeProductionInputted - coilWeightInputted;
  }

  static computeProductWeight(item: ProductListItem, coil: Coil): number {
    if (this.isEmpty(item.product.length) || this.isEmpty(item.product.weightPerMeter) || this.isEmpty(item.product.quantity)){
      return 0;
    }

    // Longueur * largeur bobine * épaisseur bobine * 7.85 /1000000
    return item.product.length * coil.width * coil.thickness * item.product.weightPerMeter * item.product.quantity;
  }

  static computeTheoricalWeight(products: ProductListItem[], coil: Coil): number {
    if (!products || products.length < 1) {
      return 0;
    }
    let productionWeight = 0;
    for (const item of products) {
      productionWeight += this.computeProductWeight(item, coil);
    }
    return productionWeight;
  }

  static compareTheoricalToInput(products: ProductListItem[], coil: Coil, coilWeightBeforeProductionInputted: number, coilWeightInputted: number): number {
    if (this.isEmpty(coilWeightInputted)) {
      return 0;
    }
    const theoricalWeight = this.computeTheoricalWeight(products, coil);
    return (Math.abs(theoricalWeight - this.computeInputProductionWeight(coilWeightBeforeProductionInputted, coilWeightInputted)) / theoricalWeight) * 100;
  }

  static isDifferenceTolerable(products: ProductListItem[], coil: Coil, coilWeightBeforeProductionInputted: number, coilWeightInputted: number): boolean {
    return !(this.compareTheoricalToInput(products, coil, coilWeightBeforeProductionInputted, coilWeightInputted) > WEIGHT_DIFFERENCE_TOLERANCE);
  }

}
