import { AxiosResponse } from 'axios';
import { APIService } from '../../shared/services/APIService';
import { DataStorage, KEY_ACCESS_TOKEN } from '../../shared/constants';
import { LoginDTO } from '../dto/LoginDTO';
import { AuthUser } from '../models/AuthUser';
import { RoleEnum } from '../../shared/enum/RoleEnum';

export class AuthService extends APIService {

  constructor() {
    super(false);
  }

  static get accessToken() {
    return DataStorage.getItem(KEY_ACCESS_TOKEN);
  }

  static get user(): AuthUser | null {
    const token = AuthService.accessToken;

    if (!token) {
      return null;
    }

    const data = AuthService.decodeJWT(token);
    if (!data) {
      return null;
    }
    return data;
  }

  static get isAuth(): boolean {
    const data = AuthService.user;
    if (!data) {
      return false;
    }

    if ((data.exp * 1000) > Date.now()) {
      return true;
    }
    DataStorage.removeItem(KEY_ACCESS_TOKEN);
    return false;
  }

  static get isAdmin(): boolean {
    if (!AuthService.isAuth) {
      return false;
    }
    return AuthService.user?.role === RoleEnum.ADMIN;
  }

  /**
   * Decode a JWT
   */
  static decodeJWT(token: string) {
    let output = token.split('.')[1].replace(/-/g, "+").replace(/_/g, "/");
    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += "==";
        break;
      case 3:
        output += "=";
        break;
      default:
        return null;
    }

    try {
      return JSON.parse(decodeURIComponent(atob(output).replace(/(.)/g, function (m, p) {
        let code = p.charCodeAt(0).toString(16).toUpperCase();
        if (code.length < 2) {
          code = '0' + code;
        }
        return '%' + code;
      })));
    } catch {
      return null;
    }
  }

  /**
   * Login
   */
  async login(dto: LoginDTO) {
    return this.http.post(`/auth/login`, dto)
      .then((result: AxiosResponse<{ accessToken: string; }>) => {
        DataStorage.setItem(KEY_ACCESS_TOKEN, result.data.accessToken);
        return AuthService.user;
      })
      .catch(() => {
        return null;
      });
  }

  /**
   * Logout
   */
  logout() {
    DataStorage.removeItem(KEY_ACCESS_TOKEN);
  }
}
