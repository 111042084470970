import {Button} from "primereact/button";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { RoleEnum } from "../../shared/enum/RoleEnum";
import { AuthService } from "../services/AuthService";
import { LoginDTO } from "../dto/LoginDTO";
import { AuthUser } from "../models/AuthUser";
import "./ButtonLogin.scss";
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from 'i18next';

interface Props extends WithTranslation {
  email: string;
  password: string;
  onLogin: (isAuth: boolean) => void;
}

function ButtonLogin(props: Props) {
  const history = useHistory();
  const location = useLocation();
  const authService = new AuthService();
  const t = props.t as TFunction;

  const handleClick = () => {
    const dto = new LoginDTO(props.email?.trim(), props.password);

    authService.login(dto).then((user: AuthUser | null) => {
      if (!user) {
        props.onLogin(false);
        return;
      }

      props.onLogin(true);

      let from = { pathname: "/" };

      if (location.state) {
        from = (location.state as any).from;
      }

      // = Admin ?
      if (user.role === RoleEnum.ADMIN) {
        from = { pathname: "/admin" };
      }

      history.replace(from);
    });
  };

  return (
      <div className="text-center">
          <Button className="button-login" type="button" label={t("UI.BUTTON.LOGIN")} onClick={() => {
              handleClick();
          }}> </Button>
      </div>

  );
}

export default withTranslation()(ButtonLogin);
