import { AxiosResponse } from "axios";
import { TFunction } from "i18next";
import { Button } from 'primereact/button';
import { Card } from "primereact/card";
import { FileUpload, FileUploadHandlerParam } from "primereact/fileupload";
import { Toast } from "primereact/toast";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { SeverityEnum } from "../../shared/enum/SeverityEnum";
import { FileUtils } from "../../shared/utils/FileUtils";
import { AdminTranslationService } from "../services/AdminTranslationService";

interface Props extends WithTranslation {
}

interface States {
}

class AdminTranslations extends React.Component<Props, States> {
  private readonly translationService: AdminTranslationService;
  private toast: Toast | null;
  private fileUpload: FileUpload | null;
  private t: TFunction;

  constructor(props: Props) {
    super(props);
    this.translationService = new AdminTranslationService();
    this.t = this.props.t;
    this.toast = null;
    this.fileUpload = null;
  }

  /**
   * On upload
   */
  handleUpload(e: FileUploadHandlerParam) {
    if (!e.files) {
      return;
    }
    this.translationService
      .uploadTranslations(e.files[0])
      .then(() => {
        this.toast?.show({
          severity: SeverityEnum.SUCCESS,
          detail: this.t("UI.ADMIN.TRANSLATIONS.TOAST_SUCCESS"),
        });
        this.fileUpload?.clear();
      })
      .catch(() => {
        this.toast?.show({
          severity: SeverityEnum.ERROR,
          detail: this.t("UI.ADMIN.TRANSLATIONS.TOAST_ERROR"),
        });
      });
  }

  /**
   * On download
   */
  handleDownload() {
    this.translationService
      .downloadTranslations()
      .then((response: AxiosResponse<Blob>) => {
        FileUtils.downloadFile(response);
      });
  }

  render() {
    return (
      <Card title={this.t("UI.ADMIN.TRANSLATIONS.TITLE")}>
        <Toast ref={(el) => (this.toast = el)}/>
        <Button
          label={this.t("UI.ADMIN.TRANSLATIONS.DOWNLOAD_BUTTON")}
          className="mb-2"
          onClick={() => this.handleDownload()}
        />

        <FileUpload
          ref={(el) => (this.fileUpload = el)}
          mode="basic"
          //accept="application/json"
          customUpload={true}
          uploadHandler={(e: FileUploadHandlerParam) => this.handleUpload(e)}
          // emptyTemplate={() => <p className="p-m-0">{this.t("ui_admin_translations_upload")}</p>}
          chooseLabel={this.t("UI.BUTTON.CHOOSE_FILE")}
          cancelLabel={this.t("UI.BUTTON.CANCEL_FILE")}
          uploadLabel={this.t("UI.BUTTON.UPLOAD_FILE")}
        />
      </Card>
    );
  }
}

export default withTranslation()(AdminTranslations);
