import React, { ReactNode } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import AdminDecisionTrees from "./components/AdminDecisionTrees";
import AdminForm from "./components/AdminForm";
import AdminTranslations from "./components/AdminTranslations";
import { Button } from 'primereact/button';
import "./AdminPage.scss";
import { TFunction } from 'i18next';
import { FormService } from '../form/services/FormService';
import { AxiosError, AxiosResponse } from 'axios';
import { Coil } from '../form/models/Coil';
import { SeverityEnum } from '../shared/enum/SeverityEnum';
import { Toast } from 'primereact/toast';
import AdminUsers from "./components/AdminUsers";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ProductionLine } from "./models/ProductionLine";
import { DefaultUnit, GetPlaceHolderSuffix, GetSuffix } from "../form/enum/UnitType";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { AdminUsersService } from "./services/AdminUsersService";
import { Checkbox } from "primereact/checkbox";
import { User } from "./models/User";
import { RoleEnum } from "./enum/RoleEnum";
import { InputNumber } from "primereact/inputnumber";
import { INPUT_NUMBER_MAX_WIDTH } from "../shared/constants";
import { ProductionSearchFieldsDTO } from "../form/dto/ProductionSearchFieldsDTO";
import { CoilService } from "../form/services/CoilService";
import { MultiSelect } from "primereact/multiselect";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { Calendar } from "primereact/calendar";
import { WeightUtils } from '../form/utils/WeightUtils';
import { FileUtils } from '../shared/utils/FileUtils';

interface Props extends WithTranslation {
}

interface States {
  administrationPage: boolean;
  numberOfProducts: number;
  production: ProductionLine[],
  displayProductionModal: boolean,
  prodWeightBefore: number | null,
  prodWeightAfter: number | null,
  displayUserModal: boolean,
  userID: string,
  userName: string,
  userPassword: string,
  userAdmin: boolean,
  userActive: boolean,
  users: User[],

  selectedCoils: Coil[],
  coils: Coil[],

  searchFieldCoilReference: string[],
  searchFieldProductReference: string[],
  searchFieldStartDate: Date | Date[] | undefined,
  searchFieldEndDate: Date | Date[] | undefined,
  searchFieldSentToMSoft: boolean | null | undefined,
}

class AdminPage extends React.Component<Props, States> {
  private t: TFunction;
  private formService: FormService;
  private toast: Toast | null;
  private readonly adminUsersService: AdminUsersService;
  private readonly productionService: FormService;
  private readonly coilService: CoilService;
  private currentProductionItem: ProductionLine | null;

  constructor(props: Props) {
    super(props);
    this.t = this.props.t;
    this.formService = new FormService();
    this.toast = null;
    this.adminUsersService = new AdminUsersService();
    this.productionService = new FormService();
    this.coilService = new CoilService();
    this.currentProductionItem = null;
    this.state = {
      administrationPage: false,
      numberOfProducts: -1,
      production: [],
      displayUserModal: false,
      prodWeightBefore: null,
      prodWeightAfter: null,
      displayProductionModal: false,
      userID: "",
      userName: "",
      userPassword: "",
      userAdmin: false,
      userActive: false,
      users: [],

      selectedCoils: [],
      coils: [],

      searchFieldCoilReference: [],
      searchFieldProductReference: [],
      searchFieldStartDate: undefined,
      searchFieldEndDate: undefined,
      searchFieldSentToMSoft: null,
    };
  }

  componentDidMount() {
    this.updateNumberOfProducts();
    this.getProduction();
    this.getUsers();
    this.getCoils();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<States>, snapshot?: any) {
    if (prevState.searchFieldCoilReference !== this.state.searchFieldCoilReference
      || prevState.searchFieldProductReference !== this.state.searchFieldProductReference
      || prevState.searchFieldStartDate !== this.state.searchFieldStartDate
      || prevState.searchFieldEndDate !== this.state.searchFieldEndDate
      || prevState.searchFieldSentToMSoft !== this.state.searchFieldSentToMSoft) {
      this.getProduction();
    }
  }

  getCoils() {
    this.coilService.getAll()
      .then(t => {
        this.setState({coils: t});
      });
  }

  updateNumberOfProducts() {
    this.formService.getMSoftProductsNumber()
      .then((res: AxiosResponse<number>) => {
        this.setState({numberOfProducts: res.data});
      })
      .catch((error: AxiosError<number>) => {
        if (error.response) {
          this.toast?.show({
            severity: SeverityEnum.ERROR,
            detail: this.t("UI.TOAST_ERROR.ERROR"),
          });
        }
        return false;
      });
  }

  /**
   * Refresh users
   */
  getUsers() {
    this.adminUsersService
      .getMany()
      .then((response: AxiosResponse<User[]>) => {
        this.setState({users: response.data});
      });
  }

  downloadCSV() {
    this.formService.downloadCSV(new ProductionSearchFieldsDTO(
      this.state.searchFieldCoilReference,
      this.state.searchFieldProductReference,
      this.state.searchFieldStartDate,
      this.state.searchFieldEndDate,
      this.state.searchFieldSentToMSoft === undefined ? null : this.state.searchFieldSentToMSoft,
    )).then();
  }

  generateMSoftProduction() {
    this.formService.generateMSoftProduction()
      .then(() => {
        this.toast?.show({
          severity: SeverityEnum.SUCCESS,
          detail: this.t("UI.TOAST_SUCCESS"),
        });
        this.updateNumberOfProducts();
        this.getProduction();
      })
      .catch((error: AxiosError<Coil>) => {
        if (error.response) {
          this.toast?.show({
            severity: SeverityEnum.ERROR,
            detail: this.t("UI.TOAST_ERROR.ERROR"),
          });
        }
        return false;
      });
  }

  /**
   * Refresh products
   */
  getProduction() {
    this.formService
      .getProduction(new ProductionSearchFieldsDTO(
        this.state.searchFieldCoilReference,
        this.state.searchFieldProductReference,
        this.state.searchFieldStartDate,
        this.state.searchFieldEndDate,
        this.state.searchFieldSentToMSoft === undefined ? null : this.state.searchFieldSentToMSoft,
      ))
      .then((response: AxiosResponse<ProductionLine[]>) => {
        this.setState({production: response.data});
      });
  }

  getAdministration() {
    return (
      <div className="grid">
        <div className="col-12 md:col-6">
          <AdminForm/>
        </div>
        <div className="col-12 md:col-6">
          <AdminTranslations/>
        </div>
        <div className="col-12 md:col-6">
          <AdminDecisionTrees/>
        </div>
        <div className="col-12 md:col-6">
          <AdminUsers modalVisible={this.state.displayUserModal}
                      displayModal={(visible: boolean) => {
                        this.setState({
                          displayUserModal: visible,
                          userID: '',
                          userName: '',
                          userPassword: '',
                          userAdmin: false,
                          userActive: true
                        });
                      }}
                      updateAction={(user: User) => {
                        this.setState({
                          displayUserModal: true,
                          userID: user._id,
                          userName: user.email,
                          userPassword: user.password,
                          userAdmin: user.role === RoleEnum.ADMIN,
                          userActive: user.isActive
                        });
                      }}
                      users={this.state.users}
          />
        </div>
      </div>
    )
  }

  actionBodyTemplate(rowData: ProductionLine) {
    return (
      <React.Fragment>
        <Button
          type="button"
          icon="pi pi-pencil"
          className="p-button-secondary p-mr-1"
          onClick={() => {
            this.currentProductionItem = rowData;
            this.setState({
              displayProductionModal: true,
              prodWeightBefore: rowData.weightBefore,
              prodWeightAfter: rowData.weightAfter
            });
          }}
        />
      </React.Fragment>
    );
  }

  dateBodyTemplate(rowData: ProductionLine) {
    return new Date(rowData.date).toLocaleDateString("fr-be", {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    });
  }

  weightBeforeDeltaBodyTemplate(rowData: ProductionLine): number | null {
    if ((rowData.weightCoilBefore !== 0 && !rowData.weightCoilBefore)
      || (rowData.weightBefore !== 0 && !rowData.weightBefore)){
      return null;
    }
    let delta = rowData.weightCoilBefore - rowData.weightBefore;
    if (delta !== 0 && !delta){
      return null;
    }
    // if (delta < 0){
    //   delta = -delta;
    // }
    return WeightUtils.roundDisplay(delta);
  }

  weightProductsDeltaDisplay(rowData: ProductionLine): number | null {
    if ((rowData.weightBefore !== 0 && !rowData.weightBefore)
      || (rowData.weightAfter !== 0 && !rowData.weightAfter)
      || (rowData.weightTheorical !== 0 && !rowData.weightTheorical)){
      return null;
    }
    let delta = (rowData.weightBefore - rowData.weightAfter) - rowData.weightTheorical;
    if (delta !== 0 && !delta){
      return null;
    }
    // if (delta < 0){
    //   delta = -delta;
    // }
    return WeightUtils.roundDisplay(delta);
  }

  productsBodyTemplate(rowData: ProductionLine) {
    let productList: ReactNode[] = [];
    let first = true;
    let index = 0;

    for (let product of rowData.products) {
      if (!first) {
        productList.push(<br key={`product_br_${index++}`}/>);
      }
      first = false;
      productList.push(
        <span
          key={`product_${index++}`}>{`${product.reference} (${product.quantity} x ${product.length}${DefaultUnit.LENGTH}) - ${product.order ?? ""}`}</span>
      );
    }
    return productList;
  }

  sentBodyTemplate(rowData: ProductionLine) {
    if (rowData.sentToMSoft) {
      return "✔";
    }
    return "";
  }

  getDataTables() {
    return (
      <div className="grid">
        {this.productionSearchFilters()}
        <DataTable
          className={"col-12"}
          sortField={"date"}
          sortOrder={-1}
          value={this.state.production.map(m => {
            return new ProductionLine(
              m._id,
              m.coilReference,
              m.userID,
              WeightUtils.roundDisplay(m.weightCoilBefore),
              WeightUtils.roundDisplay(m.weightBefore),
              this.weightBeforeDeltaBodyTemplate(m),
              WeightUtils.roundDisplay(m.weightTheorical),
              WeightUtils.roundDisplay(m.weightAfter),
              this.weightProductsDeltaDisplay(m),
              m.date,
              m.sentToMSoft,
              m.products,
            );
          })}
          rows={10}
          paginator={true}
          rowsPerPageOptions={[5, 10, 25]}
        >
          <Column field="coilReference" header={this.t("UI.ADMIN.PRODUCTS_TABLE.COIL")} sortable/>
          <Column field="date" header={this.t("UI.ADMIN.PRODUCTS_TABLE.DATE")}
                  body={this.dateBodyTemplate} sortable/>
          <Column field="weightCoilBefore" header={this.t("UI.ADMIN.PRODUCTS_TABLE.WEIGHT_KNOWN")}
                  sortable/>
          <Column field="weightBefore" header={this.t("UI.ADMIN.PRODUCTS_TABLE.WEIGHT_BEFORE")}
                  sortable/>
          <Column field="weightBeforeDelta" header={this.t("UI.ADMIN.PRODUCTS_TABLE.WEIGHT_BEFORE_DELTA")}
                  sortable/>
          <Column field="weightAfter" header={this.t("UI.ADMIN.PRODUCTS_TABLE.WEIGHT_AFTER")}
                  sortable/>
          <Column field="weightTheorical" header={this.t("UI.ADMIN.PRODUCTS_TABLE.WEIGHT_THEORICAL")}
                  sortable/>
          <Column field="weightProductsDelta" header={this.t("UI.ADMIN.PRODUCTS_TABLE.WEIGHT_PRODUCTS_DELTA")}
                  sortable/>
          <Column field="" header={this.t("UI.ADMIN.PRODUCTS_TABLE.PRODUCTS")}
                  body={this.productsBodyTemplate}/>
          <Column field="userID" header={this.t("UI.ADMIN.PRODUCTS_TABLE.USER")}
                  sortable/>
          <Column field="sentToMSoft" header={this.t("UI.ADMIN.PRODUCTS_TABLE.EXPORTED")}
                  body={this.sentBodyTemplate} sortable/>
          <Column
            body={(rowData: ProductionLine) =>
              this.actionBodyTemplate(rowData)
            }
            headerStyle={{width: "25px"}}
            bodyStyle={{textAlign: "center"}}
          />
        </DataTable>
      </div>
    )
  }

  private handleError(error: AxiosError<any>) {
    if (error.response) {
      this.toast?.show({
        severity: SeverityEnum.ERROR,
        detail: this.t("UI.TOAST_ERROR.ERROR"),
      });
    }
    return false;
  }

  postUser() {
    if (!this.state.userName || !this.state.userPassword) return;
    this.adminUsersService.post({
      id: this.state.userID,
      username: this.state.userName,
      password: this.state.userPassword,
      isadmin: this.state.userAdmin,
      isactive: this.state.userActive
    })
      .then((res: AxiosResponse<number>) => {
        this.setState({
          displayUserModal: false,
          userID: "",
          userName: "",
          userPassword: "",
          userAdmin: false,
          userActive: false
        });
        this.getUsers();
      })
      .catch((error: AxiosError<number>) => {
        this.handleError(error);
      });
  }

  userDialogFooter() {
    return (
      <div>
        <Button disabled={!this.state.userName || !this.state.userPassword}
                label={this.t("UI.CONFIRM_DIALOG.CONFIRM")} icon="pi pi-check" onClick={() => {
          this.postUser();
        }}/>
        <Button label={this.t("UI.CONFIRM_DIALOG.CANCEL")} icon="pi pi-times" onClick={() => {
          this.setState({
            displayUserModal: false,
            userID: "",
            userName: "",
            userPassword: "",
            userAdmin: false,
            userActive: false
          });
        }}/>
      </div>
    )
  }

  userDialog() {
    return (
      <Dialog header={this.t("UI.ADMIN.USERS.DIALOG_TITLE")} footer={this.userDialogFooter()}
              visible={this.state.displayUserModal} style={{width: '50vw'}} modal
              onHide={() => this.setState({displayUserModal: false})}>
        <div className="col-12 grid flex justify-content-center">
          <label htmlFor="user-name" className="col-10 justify-content-begin">
            {this.t("UI.ADMIN.USERS.NAME")}
          </label>
          <InputText id="user-name" className="col-10 justify-content-center"
                     onChange={(e) => this.setState({userName: e.currentTarget.value})}
                     value={this.state.userName ?? undefined}
          />
        </div>
        <div className="col-12 grid flex justify-content-center">
          <label htmlFor="user-name" className="col-10 justify-content-begin">
            {this.t("UI.ADMIN.USERS.PASSWORD")}
          </label>
          <InputText id="user-name" className="col-10 justify-content-center"
                     onChange={(e) => this.setState({userPassword: e.currentTarget.value})}
                     value={this.state.userPassword ?? undefined}
          />
        </div>
        <div className="col-12 grid flex justify-content-begin">
          <Checkbox id="user-admin" className="col-offset-1"
                    onChange={(e) => {
                      this.setState({userAdmin: e.checked});
                    }}
                    checked={this.state.userAdmin}
          />
          <label htmlFor="user-admin">
            {this.t("UI.ADMIN.USERS.IS_ADMIN")}
          </label>
          <Checkbox id="user-active" className="col-offset-2"
                    onChange={(e) => {
                      this.setState({userActive: e.checked});
                    }}
                    checked={this.state.userActive}
          />
          <label htmlFor="user-active">
            {this.t("UI.ADMIN.USERS.IS_ACTIVE")}
          </label>
        </div>
      </Dialog>
    )
  }

  canUpdateProductionItem(): boolean {
    return !(!this.state.prodWeightBefore || !this.state.prodWeightAfter);
  }

  updateProductionItem() {
    if (!this.canUpdateProductionItem() || !this.currentProductionItem) return;
    this.productionService.updateSubmission(new ProductionLine(
      this.currentProductionItem._id,
      this.currentProductionItem.userID,
      this.currentProductionItem.coilReference,
      this.currentProductionItem.weightCoilBefore,
      this.state.prodWeightBefore ?? 0,
      0,
      this.currentProductionItem.weightTheorical,
      this.state.prodWeightAfter ?? 0,
      0,
      this.currentProductionItem.date,
      this.currentProductionItem.sentToMSoft,
      this.currentProductionItem.products
    ))
      .then((res: AxiosResponse<void>) => {
        this.getProduction();
        this.setState({displayProductionModal: false});
      })
      .catch((error: AxiosError<void>) => {
        this.handleError(error);
      });
  }

  productionDialogFooter() {
    return (
      <div>
        <Button disabled={!this.canUpdateProductionItem()}
                label={this.t("UI.CONFIRM_DIALOG.CONFIRM")} icon="pi pi-check" onClick={() => {
          this.updateProductionItem();
        }}/>
        <Button label={this.t("UI.CONFIRM_DIALOG.CANCEL")} icon="pi pi-times" onClick={() => {
          this.setState({
            displayProductionModal: false,
            prodWeightBefore: null,
            prodWeightAfter: null
          });
        }}/>
      </div>
    )
  }

  productionDialog() {
    return (
      <Dialog header={this.t("UI.ADMIN.PRODUCTION.DIALOG_TITLE")}
              footer={this.productionDialogFooter()}
              visible={this.state.displayProductionModal} style={{width: '50vw'}} modal
              onHide={() => this.setState({displayProductionModal: false})}>
        <div className="col-12 grid flex justify-content-center">
          <label htmlFor="user-name" className="align-self-center" style={{marginRight: "25px"}}>
            {this.t("UI.ADMIN.PRODUCTION.WEIGHT_BEFORE")}
          </label>
          <InputNumber id="prod-wbefore" className="justify-content-begin" min={0}
                       inputStyle={{maxWidth: INPUT_NUMBER_MAX_WIDTH}}
                       onChange={(e) => this.setState({prodWeightBefore: e.value})}
                       value={this.state.prodWeightBefore ?? undefined}
                       placeholder={`${this.t("UI.COIL_SEARCH.COIL_FORM.WEIGHT")}${GetPlaceHolderSuffix(DefaultUnit.WEIGHT, this.t)}`}
                       suffix={GetSuffix(DefaultUnit.WEIGHT)} mode="decimal" inputMode="decimal"
                       minFractionDigits={1} maxFractionDigits={2}
          />
        </div>
        <div className="col-12 grid flex justify-content-center">
          <label htmlFor="user-name" className="align-self-center" style={{marginRight: "25px"}}>
            {this.t("UI.ADMIN.PRODUCTION.WEIGHT_AFTER")}
          </label>
          <InputNumber id="prod-wafter" className="justify-content-begin" min={0}
                       inputStyle={{maxWidth: INPUT_NUMBER_MAX_WIDTH}}
                       onChange={(e) => this.setState({prodWeightAfter: e.value})}
                       value={this.state.prodWeightAfter ?? undefined}
                       placeholder={`${this.t("UI.COIL_SEARCH.COIL_FORM.WEIGHT")}${GetPlaceHolderSuffix(DefaultUnit.WEIGHT, this.t)}`}
                       suffix={GetSuffix(DefaultUnit.WEIGHT)} mode="decimal" inputMode="decimal"
                       minFractionDigits={1} maxFractionDigits={2}
          />
        </div>
      </Dialog>
    )
  }

  productionSearchFilters() {
    return (
      <div className="col-12">
        <div className="col-12">
          <label htmlFor="search-coilRef" className="col-3 align-self-center"
                 style={{marginRight: "10px"}}>
            {this.t("UI.ADMIN.SEARCH_FORM.COIL")}
          </label>
          <MultiSelect id="search-coilRef"
                       className="col-9"
                       multiple={true}
                       value={this.state.selectedCoils}
                       options={this.state.coils}
                       onChange={(e) => {
                         this.setState({searchFieldCoilReference: e.value.map(m => m.reference), selectedCoils: e.value});
                       }}
                       placeholder={this.t("UI.COIL_SEARCH.SEARCH_BUTTON")}
                       optionLabel="reference"
                       filter filterBy="reference"
          />
        </div>
        <div className="col-12">
          <label htmlFor="search-product" className="col-2 align-self-center"
                 style={{marginRight: "10px"}}>
            {this.t("UI.ADMIN.SEARCH_FORM.PRODUCT")}
          </label>
          <InputText id="search-product" className="col-4"
                     onBlur={(e) => this.setState({searchFieldProductReference: e.currentTarget.value && e.currentTarget.value !== "" ? [e.currentTarget.value] : []})}
          />
          <TriStateCheckbox id="user-admin" className="col-offset-1 align-self-center" style={{margin: "10px"}}
                            onChange={(e) => {
                              this.setState({searchFieldSentToMSoft: e.value});
                            }}
                            value={this.state.searchFieldSentToMSoft}
          />
          <label htmlFor="search-sent" className="col-2 align-self-center"
                 style={{marginLeft: "10px"}}>
            {this.t("UI.ADMIN.SEARCH_FORM.SENT_TO_MSOFT")}
          </label>
        </div>
        <div className="col-12">
          <label htmlFor="search-start" className="col-2 align-self-center"
                 style={{marginRight: "10px"}}>
            {this.t("UI.ADMIN.SEARCH_FORM.START_DATE")}
          </label>
          <Calendar id="search-start" className="col-4"
                    value={this.state.searchFieldStartDate ?? undefined}
                    onChange={(e) => {
                      this.setState({searchFieldStartDate: e.value});
                    }}
          />
          <label htmlFor="search-end" className="col-2 align-self-center"
                 style={{marginRight: "10px"}}>
            {this.t("UI.ADMIN.SEARCH_FORM.END_DATE")}
          </label>
          <Calendar id="search-end" className="col-4"
                    value={this.state.searchFieldEndDate ?? undefined}
                    onChange={(e) => {
                      this.setState({searchFieldEndDate: e.value});
                    }}
          />
        </div>
      </div>
    )
  }

  render() {
    const currentDisplay = this.state.administrationPage ? this.getAdministration() : this.getDataTables();
    return (
      <div className="container">
        <Toast ref={(el) => (this.toast = el)}/>
        {this.userDialog()}
        {this.productionDialog()}
        <div
          className={"top-actions flex justify-content-between"}>
          {this.state.administrationPage &&
          <Button onClick={() => this.generateMSoftProduction()}
                  icon="pi pi-cloud-download"
                  iconPos="left"
                  label={this.t("UI.ADMIN.MSOFT_PRODUCTION") + ` (${this.state.numberOfProducts > -1 ? this.state.numberOfProducts : '...'})`}
                  className="flex align-self-center"/>
          }
          {!this.state.administrationPage &&
          <Button onClick={() => this.downloadCSV()}
                  icon="pi pi-cloud-download"
                  iconPos="left"
                  label={this.t("UI.ADMIN.EXPORT_CSV")}
                  className="flex align-self-center"/>
          }
          <Button
            onClick={() => this.setState({administrationPage: !this.state.administrationPage})}
            icon="pi pi-list"
            iconPos="left"
            label={this.state.administrationPage ? this.t("UI.ADMIN.REPORTS") : this.t("UI.ADMIN.ADMINISTRATION")}
            className="flex align-self-center"/>
        </div>
        {currentDisplay}
      </div>
    );
  }
}

export default withTranslation()(AdminPage);
