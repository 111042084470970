import { TFunction } from "i18next";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { FormService } from "../services/FormService";
import { RadioButton } from 'primereact/radiobutton';

interface Props extends WithTranslation {
  name: string;
  currentValue: boolean | null;
  onValueChange: (v: any) => void;
}

class FormBool extends React.Component<Props> {
  private t: TFunction;
  private formService: FormService;

  constructor(props: Props) {
    super(props);
    this.formService = new FormService();
    this.t = this.props.t;
  }

  render() {
    return (
      <div className="grid p-formgrid">
        <div className="col-12">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a onClick={() => this.props.onValueChange(true)}>
            <label>{this.t("UI.INPUT.YES")}</label>
            <RadioButton
              id={this.props.name + "yes"}
              checked={this.props.currentValue === true}
              onChange={() => this.props.onValueChange(true)}
            />
          </a>
        </div>
        <div className="col-12">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a onClick={() => this.props.onValueChange(false)}>
            <label>{this.t("UI.INPUT.NO")}</label>
            <RadioButton
              id={this.props.name + "no"}
              checked={this.props.currentValue === false}
              onChange={() => this.props.onValueChange(false)}
            />
          </a>
        </div>
      </div>
    );
  }
}

export default withTranslation()(FormBool);
