import { TFunction } from "i18next";
import React, { ReactNode } from "react";
import { ChevronLeft, Send } from "react-feather";
import { withTranslation, WithTranslation } from "react-i18next";
import { ResponsesDTO } from "../dto/ResponsesDTO";
import { Form } from "../models/Form";
import { FormQuestion } from "../models/FormQuestion";
import { FormService } from "../services/FormService";
import { FormUtils } from "../utils/FormUtils";
import "./FormSummary.scss";
import { QuestionType } from '../enum/QuestionType';
import { Product } from '../models/Product';
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from "primereact/column";
import ShowData from "./ShowData";
import { InputNumber } from "primereact/inputnumber";
import { INPUT_NUMBER_MAX_WIDTH } from '../../shared/constants';
import { DefaultUnit, GetPlaceHolderSuffix, GetSuffix } from '../enum/UnitType';
import { Toast } from 'primereact/toast';
import { AxiosError } from 'axios';
import { Coil } from '../models/Coil';
import { SeverityEnum } from '../../shared/enum/SeverityEnum';
import { InputText } from 'primereact/inputtext';

interface Props extends WithTranslation {
  form: Form;
  history: number[];
  values: Record<string, any>;
  onPreviousClick: () => void;
  onQuestionClick: (questionId: number) => void;
  onSubmitForm: (product: Product) => void;
  length: number | null;
  quantity: number | null;
  width: number | null;
  thickness: number | null;
  order: string | null;
}

interface States {
  product: Product | null,
  length: number | null,
  quantity: number | null,
  thickness: number | null,
  width: number | null,
  requiresWidth: boolean,
  order: string,
}

class FormSummary extends React.Component<Props, States> {
  private formService: FormService;
  private t: TFunction;
  private isOnMobile: boolean = false;
  private toast: Toast | null;

  constructor(props: Props) {
    super(props);
    this.formService = new FormService();
    this.t = this.props.t;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.isOnMobile = true;
    }
    this.toast = null;
    this.state = {
      product: null,
      length: this.props.length ?? null,
      quantity: this.props.quantity ?? null,
      thickness: this.props.thickness ?? null,
      width: this.props.width ?? null,
      requiresWidth: false,
      order: this.props.order ?? "",
    }
  }

  componentDidMount() {
    this.setState({requiresWidth: this.props.values.hasOwnProperty('largeur') && this.props.values['largeur'] === "largeur_sur_mesure"});
    this.searchProductReference();
  }

  /**
   * Submit the form
   */
  handleSubmitClick() {
    if (!this.state.product) {
      return;
    }
    const prod = new Product(
      this.state.product.reference,
      this.state.product.weightPerMeter,
      this.state.length ?? 0,
      this.state.quantity ?? 0,
      this.state.width ?? 0,
      this.state.thickness ?? 0,
      (!this.state.width || this.state.width === 0 ? '/' : this.state.width.toString()),
      this.state.order,
    );
    this.props.onSubmitForm(prod);
  }

  searchProductReference() {
    // = Get values according the history
    const values: Record<string, any> = {};
    for (let i = 0; i < this.props.history.length; i++) {
      const id = this.props.history[i];
      const question = this.props.form.questions[id];

      values[question.question] = this.props.values[question.question];
    }

    // = Send DTO
    const dto = new ResponsesDTO(values);
    this.formService
      .getProduct(this.props.form.form, dto)
      .then((product: Product) => {
        this.setState({ product: product });
      })
      .catch((error: AxiosError<Coil>) => {
        if (error.response) {
          this.toast?.show({
            severity: SeverityEnum.ERROR,
            detail: this.t(error.response.status === 400 ? "UI.SUMMARY.TOAST_INVALID_FORM" : (error.response.status === 404 ? "UI.TOAST_ERROR.NOT_FOUND" : "UI.TOAST_ERROR.ERROR")),
          });
        }
        return false;
      });
  }

  bottomActions() {
    return (
      <div className="form-actions-wrapper">
        <div className="left-actions">
          <Button
            className={"secondary"}
            onClick={() => this.props.onPreviousClick()}
          >
            <ChevronLeft/> {this.formService.t(this.t, "UI.BUTTON.PREVIOUS", false, true)}
          </Button>
        </div>
        <div className="right-actions">
          <Button onClick={() => this.handleSubmitClick()} disabled={!this.state.length || !this.state.quantity || !this.state.thickness || !this.state.order || (this.state.requiresWidth && !this.state.width)}>
            {this.formService.t(this.t, "UI.SUMMARY.VALID_BUTTON", false, true)}{" "}
            <Send style={{ transform: "rotate(45deg)" }}/>
          </Button>
        </div>
      </div>
    )
  }

  render() {
    if (this.state.product === null) {
      return (
        <React.Fragment>
          <Toast ref={(el) => (this.toast = el)}/>
          <div>Loading...</div>
          {this.bottomActions()}
        </React.Fragment>
      );
    }
    const data: ReactNode[] = [];
    if (this.isOnMobile) {
      window.scroll(0, 0)
    }
    FormUtils.formatValue(
      this.t,
      this.props.form,
      this.props.history,
      this.props.values,
      (id: number, question: FormQuestion, value: string) => {
        if (question.type === QuestionType.NUMBER) {
          data.push({
            id: id,
            question: this.t(`QUESTION.LABEL.${question.question}`),
            value: value
          });
        } else {
          data.push({
            id: id,
            question: this.t(`QUESTION.LABEL.${question.question}`),
            value: this.t(`RESPONSES.${value}`),
          });
        }
      }
    );

    return (
      <div className="container containerSummary">
        <Toast ref={(el) => (this.toast = el)}/>
        <div className="form-content">
          <div className="form-content-header">
            <h1>{this.formService.t(this.t, "UI.SUMMARY.TITLE", true)}</h1>
          </div>
          <div className="form-content-body">
            <Panel>
              <DataTable
                onRowClick={(row: any) => {
                  this.props.onQuestionClick(row?.data.id);
                }}
                value={data}
                className={"col-12"}>
                <Column field="question" header={this.t("UI.SUMMARY.QUESTION")}/>
                <Column field="value" header={this.t("UI.SUMMARY.RESPONSE")}/>
              </DataTable>
            </Panel>
          </div>
        </div>
        <div>
          <ShowData label={this.t("UI.SUMMARY.PRODUCT_REFERENCE")} data={this.state.product.reference} boldData={true}/>
          <div className="flex grid">
            <div className="flex col-6 justify-content-end">
              <label htmlFor="summary-length" className="align-self-center">
                {this.t("UI.SUMMARY.LENGTH")}
              </label>
            </div>
            <div className="flex col-6 justify-content-start">
              <InputNumber id="summary-length" className="align-self-center" min={0} inputStyle={{ maxWidth: INPUT_NUMBER_MAX_WIDTH }}
                           onChange={(e) => this.setState({ length: e.value })}
                           placeholder={`${this.t("UI.COIL_SEARCH.COIL_FORM.LENGTH")}${GetPlaceHolderSuffix(DefaultUnit.LENGTH, this.t)}`}
                           value={this.state.length ?? undefined} suffix={GetSuffix(DefaultUnit.LENGTH)}
                           mode="decimal" inputMode="decimal" minFractionDigits={1} maxFractionDigits={2}
              />
            </div>
          </div>
          <div className="flex grid">
            <div className="flex col-6 justify-content-end">
              <label htmlFor="summary-thickness" className="align-self-center">
                {this.t("UI.SUMMARY.THICKNESS")}
              </label>
            </div>
            <div className="flex col-6 justify-content-start">
              <InputNumber id="summary-thickness" className="align-self-center" min={0} max={1} inputStyle={{ maxWidth: INPUT_NUMBER_MAX_WIDTH }}
                           onChange={(e) => this.setState({ thickness: e.value })}
                           placeholder={`${this.t("UI.COIL_SEARCH.COIL_FORM.THICKNESS")}${GetPlaceHolderSuffix(DefaultUnit.THICKNESS, this.t)}`}
                           value={this.state.thickness ?? undefined} suffix={GetSuffix(DefaultUnit.THICKNESS)}
                           mode="decimal" inputMode="decimal" minFractionDigits={1} maxFractionDigits={2}
              />
            </div>
          </div>
          {this.state.requiresWidth && (
            <div className="flex grid">
              <div className="flex col-6 justify-content-end">
                <label htmlFor="summary-width" className="align-self-center">
                  {this.t("UI.SUMMARY.WIDTH")}
                </label>
              </div>
              <div className="flex col-6 justify-content-start">
                <InputNumber id="summary-width" className="align-self-center" min={0} inputStyle={{ maxWidth: INPUT_NUMBER_MAX_WIDTH }}
                             onChange={(e) => this.setState({ width: e.value })}
                             placeholder={`${this.t("UI.COIL_SEARCH.COIL_FORM.WIDTH")}${GetPlaceHolderSuffix(DefaultUnit.WIDTH, this.t)}`}
                             value={this.state.width ?? undefined} suffix={GetSuffix(DefaultUnit.WIDTH)}
                             mode="decimal" inputMode="decimal" minFractionDigits={1} maxFractionDigits={2}
                />
              </div>
            </div>
          )}
          <div className="flex grid">
            <div className="flex col-6 justify-content-end">
              <label htmlFor="summary-quantity" className="align-self-center">
                {this.t("UI.SUMMARY.QUANTITY")}
              </label>
            </div>
            <div className="flex col-6 justify-content-start">
              <InputNumber id="summary-quantity" className="align-self-center" min={0} inputStyle={{ maxWidth: INPUT_NUMBER_MAX_WIDTH }}
                           onChange={(e) => this.setState({ quantity: e.value })}
                           placeholder={this.t("UI.COIL_SEARCH.COIL_FORM.QUANTITY")} value={this.state.quantity ?? undefined}
              />
            </div>
          </div>
          <div className="flex grid">
            <div className="flex col-6 justify-content-end">
              <label htmlFor="summary-order" className="align-self-center">
                {this.t("UI.SUMMARY.ORDER")}
              </label>
            </div>
            <div className="flex col-6 justify-content-start">
              <InputText id="summary-order" className="align-self-center" min={0} style={{ maxWidth: INPUT_NUMBER_MAX_WIDTH }}
                           onChange={(e) => this.setState({ order: e.target.value })}
                           placeholder={this.t("UI.SUMMARY.ORDER")} value={this.state.order ?? undefined}
              />
            </div>
          </div>
        </div>
        {this.bottomActions()}
      </div>
    );
  }
}

export default withTranslation()(FormSummary);
