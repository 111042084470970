export class Product {

  constructor(
    readonly reference: string,
    readonly weightPerMeter: number,
    readonly length: number,
    readonly quantity: number,
    readonly width: number,
    readonly thickness: number,
    readonly widthDisplay: string,
    readonly order: string,
  ) {
  }
}
