import { OfflineDataStorage } from "../../shared/constants";

export class StorageUtils {
  static GetItem(key: string): any {
    let unparsedItem = OfflineDataStorage.getItem(key);
    let Item = null;
    if (unparsedItem && unparsedItem !== 'undefined') {
      Item = JSON.parse(unparsedItem);
    }
    return Item;
  }

}
