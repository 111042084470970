import i18n, { TFunction } from "i18next";
import React, { Component, ReactNode } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import ImageWrapper from "./../shared/components/ImageWrapper";
import FormBool from "./components/FormBool";
import FormDropdown from "./components/FormDropdown";
import FormInputNumber from "./components/FormInputNumber";
import FormMatrix from "./components/FormMatrix";
import FormRadioImage from "./components/FormRadioImage";
import FormRadioList from "./components/FormRadioList";
import FormStepper from "./components/FormStepper";
import FormSummary from "./components/FormSummary";
import { QuestionType } from "./enum/QuestionType";
import "./FormPage.scss";
import { Form } from "./models/Form";
import { FormQuestion } from "./models/FormQuestion";
import { DecisionTreeService } from "./services/DecisionTreeService";
import { FormService } from "./services/FormService";
import { FormUtils } from "./utils/FormUtils";
import { TranslationService } from './services/TranslationService';
import FormLongText from './components/FormLongText';
import StartProduction from './components/StartProduction';
import { Product } from './models/Product';
import CoilSearch from './components/CoilSearch';
import { Button } from "primereact/button";
import ProductsList from "./components/ProductsList";
import { ProductListItem } from "./models/ProductListItem";
import CoilWeight from './components/CoilWeight';
import CoilWeightAfterProduction from './components/CoilWeightAfterProduction';
import {
  KEY_OFFLINE_PRODUCT_LISTS,
  KEY_OFFLINE_STATE,
  OfflineDataStorage
} from '../shared/constants';
import { ProductionDTO } from './dto/ProductionDTO';
import { AxiosError } from 'axios';
import { SeverityEnum } from "../shared/enum/SeverityEnum";
import { Toast } from "primereact/toast";
import { StorageUtils } from "./utils/StorageUtils";
import { Coil } from "./models/Coil";
import SynchronizeButton from './components/SynchronizeButton';
import { WeightUtils } from './utils/WeightUtils';

interface States {
  // start page
  productionStarted: boolean;

  // coil selection
  showCoilReference: boolean;
  coil: Coil | null;

  // coil weight
  showCoilWeight: boolean;
  coilWeight: number | null;

  // products list
  product: Product | null;
  products: ProductListItem[];
  displayProductsList: boolean;
  productsValidated: boolean;
  editKey: number | null;

  // questions
  form: Form | null;
  currentQuestion: number;
  lastQuestion?: number;
  questionHistory: number[];
  values: Record<string, any>;

  // questions summary
  summary: boolean;
  displaySummaryStep: boolean;

  // coil weight after production
  coilWeightAfterProduction: number | null;

  // general
  waitValue: boolean;
  defaultValue: any;
  conditionalInfo: string;
}

interface Props extends WithTranslation, RouteComponentProps {
}

class FormPage extends Component<Props, States> {
  private readonly bottomLeftStyle = {position: "absolute", bottom: "10px", left: "10px"}
  private readonly bottomRightStyle = {position: "absolute", bottom: "10px", right: "10px"}
  private formService: FormService;
  private translationService: TranslationService;
  private decisionTreeService: DecisionTreeService;
  private t: TFunction;
  private useSaveState: boolean;
  private toast: Toast | null;
  private forceCannotContinue: boolean;

  private historyListener: () => void;

  constructor(props: Props) {
    super(props);
    this.useSaveState = false;
    this.formService = new FormService();
    this.translationService = new TranslationService();
    this.decisionTreeService = new DecisionTreeService();
    this.t = this.props.t;
    this.toast = null;
    this.forceCannotContinue = false;
    this.historyListener = () => {
    };
    this.state = {
      productsValidated: false,
      editKey: null,
      showCoilReference: false,
      coil: null,
      showCoilWeight: false,
      coilWeight: null,
      form: null,
      currentQuestion: 0,
      questionHistory: [],
      values: {},
      productionStarted: false,
      summary: false,
      displaySummaryStep: false,
      product: null,
      products: [],
      displayProductsList: true,
      coilWeightAfterProduction: null,
      waitValue: false,
      defaultValue: null,
      conditionalInfo: ''
    };
  }

  get hasPreviousPage() {
    return this.state.currentQuestion !== 0;
  }

  get hasNextPage() {
    return !this.state.summary;
  }

  get isLastQuestion() {
    return this.state.summary || !this.state.lastQuestion || this.state.currentQuestion === this.state.lastQuestion;
  }

  get canSubmitQuestion() {
    const question = this.state.form?.questions[this.state.currentQuestion];
    if (!question) {
      return false;
    }

    let isValid = true;
    let requiredOptionnal = false;
    if (question) {
      switch (question.type) {
        case QuestionType.NUMBER:
          if (question.validation.min !== undefined && question.validation.min !== null && isValid) {
            isValid = this.getCurrentValue() >= question.validation.min;
          }
          break;
        case QuestionType.LONG_TEXT:
          requiredOptionnal = true;
          break;
      }
    }

    question.isValid = isValid;

    return (
      (this.getCurrentValue() !== null || requiredOptionnal) &&
      !this.state.waitValue &&
      !this.state.summary &&
      isValid
    );
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<States>, snapshot?: any) {
    if (this.useSaveState) {
      this.saveState();
      this.useSaveState = false;
    }
  }

  componentDidMount() {
    document.addEventListener("keyup", this.handleKeyUp.bind(this));

    // = Listen to the history to prevent back action
    // Clone
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: this.props.location.search,
    });

    // 'even' : allows you to execute the "previous" action only once when going backwards
    let even = 0;
    this.historyListener = this.props.history.listen((location, action) => {
      // Back action
      if (action === "POP") {
        this.props.history.go(1);

        if (this.state.product) {
          this.gotoSummary();
        } else {
          even++;
          if (even % 2 === 0) {
            this.handlePreviousClick(this.state.summary);
            even = 0;
          }
        }
      }
    });

    this.props.i18n.on("languageChanged", this.generateImage.bind(this));

    this.formService.getForm("default").then((resultQuestions) => {
      const form = resultQuestions.data;
      for (const question in form.questions) {
        if (form.questions[question].images && form.questions[question].images.length > 0) {
          form.questions[question].refImages = form.questions[question].images.slice(0);
        }
        if (form.questions[question].imageValues && form.questions[question].imageValues.length > 0) {
          form.questions[question].refImageValues = form.questions[question].imageValues.slice(0);
        }
      }
      this.generateImage(i18n.language, form);
    });

    this.decisionTreeService.getProductsTree();
    this.decisionTreeService.getWeightsTree();
    this.synchronizeStorageProduction();
  }

  synchronizeStorageProduction() {
    const localProd = StorageUtils.GetItem(KEY_OFFLINE_PRODUCT_LISTS);
    OfflineDataStorage.setItem(KEY_OFFLINE_PRODUCT_LISTS, JSON.stringify([]));
    if (localProd && localProd.length > 0) {
      for (const index in localProd) {
        const prodItem = localProd[index];
        this.formService.sendSubmission(prodItem)
          .then(() => {
            //
          })
          .catch((error: AxiosError<void>) => {
            this.addProductListToStorage(prodItem);
            if (error.response) {
              this.toast?.show({
                severity: SeverityEnum.ERROR,
                detail: this.t("UI.TOAST_ERROR.FAILED_SYNCHRONIZATION"),
              });
            }
          });
      }
    }
    else{
      this.toast?.show({
        severity: SeverityEnum.SUCCESS,
        detail: this.t("UI.TOAST_ERROR.NOTHING_TO_SYNCHRONIZE"),
      });
    }
  }

  async generateImage(code: string, form: Form) {
    if (!form) {
      form = this.state.form ? this.state.form : {} as Form;
    }

    for (const question in form.questions) {
      for (const i in form.questions[question].refImages) {
        const translation = this.formService.t(this.t, form.questions[question].refImages[i], false, false, false, true);
        if (translation) {
          form.questions[question].images[i] = translation;
        }
      }
      for (const i in form.questions[question].refImageValues) {
        const translation = this.formService.t(this.t, form.questions[question].refImageValues[i], false, false, false, true);
        if (translation) {
          form.questions[question].imageValues[i] = translation;
        }
      }
    }
    this.setState({form: form});
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleKeyUp.bind(this));
    // Unregister history listener
    this.historyListener();
  }

  saveState(): void {
    OfflineDataStorage.setItem(KEY_OFFLINE_STATE, JSON.stringify(this.state));
  }

  getSavedState(): any {
    const unparsedSavedState = OfflineDataStorage.getItem(KEY_OFFLINE_STATE);
    if (unparsedSavedState) {
      return JSON.parse(unparsedSavedState);
    }
    return null;
  }

  useSavedState(): void {
    const savedState = this.getSavedState();
    const form = this.state.form;
    if (savedState) {
      this.setState(savedState);
      if (form && form.questions.length > 0){
        this.setState({form: form});
      }
    } else {
      this.resetState(true);
    }
  }

  canContinue(): boolean {
    if (this.forceCannotContinue) return false;
    const savedState = this.getSavedState();
    return savedState && savedState !== 'undefined' && savedState.productionStarted;
  }

  resetState(skipStart = false) {
    this.setState({
      productionStarted: skipStart,
      productsValidated: false,
      editKey: null,
      showCoilReference: false,
      coil: null,
      showCoilWeight: false,
      coilWeight: null,
      currentQuestion: 0,
      questionHistory: [],
      values: {},
      summary: false,
      displaySummaryStep: false,
      product: null,
      products: [],
      displayProductsList: true,
      coilWeightAfterProduction: null,
      waitValue: false,
      defaultValue: null,
      conditionalInfo: ''
    });
  }

  addProductListToStorage(item: ProductionDTO): void {
    let storageProductsList = StorageUtils.GetItem(KEY_OFFLINE_PRODUCT_LISTS);
    if (!storageProductsList) {
      storageProductsList = [];
    }
    storageProductsList.push(item);
    OfflineDataStorage.setItem(KEY_OFFLINE_PRODUCT_LISTS, JSON.stringify(ProductionDTO));
    OfflineDataStorage.removeItem(KEY_OFFLINE_STATE);
  }

  sendSubmission(weight: number): void {
    if (!this.state.coil || !this.state.coilWeight) {
      return;
    }
    const dto = new ProductionDTO(
      this.state.coil.reference,
      this.state.coil.weight,
      this.state.coilWeight,
      WeightUtils.computeTheoricalWeight(this.state.products, this.state.coil),
      weight,
      this.state.products.map(m => m.product)
    );
    if (window.navigator.onLine) {
      this.forceCannotContinue = true;
      this.formService.sendSubmission(dto)
        .then(() => {
          this.useSaveState = true;
          this.resetState();
          this.forceCannotContinue = false;
        })
        .catch((error: AxiosError<void>) => {
          if (error.response) {
            this.toast?.show({
              severity: SeverityEnum.ERROR,
              detail: this.t("UI.TOAST_ERROR.ERROR"),
            });
          }
        });
    } else {
      this.addProductListToStorage(dto);
      this.useSaveState = true;
      this.resetState();
    }
  }

  /**
   * Handle: reset
   *
   * Reset the form
   */
  handleReset() {
    this.setState({
      questionHistory: [],
      currentQuestion: 0,
      product: null,
      summary: false,
      values: {},
      displaySummaryStep: false,
      displayProductsList: false,
      waitValue: false,
    });
  }

  gotoSummary() {
    this.setState({summary: true, product: null});
  }

  /**
   * Handle: keyup event
   *
   * Submit next question and form
   */
  handleKeyUp(e: KeyboardEvent) {
    if (e.key && e.key.toLowerCase() === "enter" && this.canSubmitQuestion) {
      this.handleNextClick();
    }
  }

  /**
   * Handle: onValueChange/onChange
   */
  handleValueChange(value: string | number, nextQuestionOnFill: boolean = false) {
    const question = this.state.form?.questions[this.state.currentQuestion];

    if (question && value !== null) {
      // Add value
      const lastValues = this.state.values;
      const values = Object.assign({}, lastValues);
      values[question.question] = value;

      // Remove next questions in the history
      let questionHistory = this.state.questionHistory;
      const questionIndex = questionHistory.findIndex(
        (id: number) => id === this.state.currentQuestion
      );
      if (questionIndex !== -1) {
        questionHistory.splice(
          questionIndex + 1,
          questionHistory.length - (questionIndex + 1)
        );
        questionHistory = questionHistory.slice(0);
      }
      this.setState({
        values,
        questionHistory,
        summary: false,
        displaySummaryStep: false,
      });

      if (nextQuestionOnFill) {
        setTimeout(() => {
          if (question !== this.state.form?.questions[this.state.currentQuestion]) {
            return;
          }
          if (this.canSubmitQuestion) {
            this.handleNextClick();
          }
        }, 0);
      }
    }
  }

  /**
   * Handle: onClick for the next/summary button
   */
  async handleNextClick(goToLast?: boolean) {
    if (!this.state.form || this.state.summary) {
      return;
    }

    this.setState({waitValue: true});

    // = Check conditions
    const nextQuestion = await this.getNextQuestion(
      this.state.currentQuestion,
      this.state.form.questions,
    );

    // = Access to summary
    if (nextQuestion.nextQuestion === this.state.currentQuestion) {
      this.setState({
        questionHistory: nextQuestion.questionHistory,
        summary: true,
        waitValue: false,
      });
      return;
    }

    const currentQuestion = goToLast ? nextQuestion.lastQuestion : nextQuestion.nextQuestion;

    Promise.all([
      this.computeDefaultValue(currentQuestion, nextQuestion.questionHistory),
      this.computeConditionalInfo(currentQuestion, nextQuestion.questionHistory),
    ])
      .then((data: any) => {
        this.setState({
          currentQuestion,
          lastQuestion: nextQuestion.lastQuestion,
          questionHistory: nextQuestion.questionHistory,
          waitValue: false,
          defaultValue: data[0],
          conditionalInfo: data[1],
        });
      });
  }

  /**
   * Handle: onClick for the previous button
   */
  async handlePreviousClick(fromSummary: boolean = false) {
    if (!this.state.form) {
      return;
    }
    if (!this.hasPreviousPage){
      this.setState({displayProductsList: true, editKey: null});
      return;
    }

    const questionHistory = this.state.questionHistory;
    const questionIndex = questionHistory.findIndex(
      (id: number) => id === this.state.currentQuestion
    );
    let previousQuestion = questionHistory[questionHistory.length - 1];
    if (questionIndex !== -1 && !this.state.summary) {
      previousQuestion = questionHistory[questionIndex - 1];
    }

    Promise.all([
      this.computeDefaultValue(previousQuestion),
      this.computeConditionalInfo(previousQuestion),
    ])
      .then((data: any) => {
        this.setState({
          currentQuestion: previousQuestion,
          displaySummaryStep: fromSummary,
          summary: false,
          defaultValue: data[0],
          conditionalInfo: data[1],
        });
      });
  }

  private getQuestionHistoryByQuestion(questionId) {
    const questionHistory: number[] = [];
    for (const questionHistoryId of this.state.questionHistory) {
      if (questionHistoryId < questionId) {
        questionHistory.push(questionHistoryId);
      }
    }
    return questionHistory;
  }

  async computeDefaultValue(questionIndex: number, questionHistory?: number[]) {
    const question = this.state.form?.questions[questionIndex];
    if (question) {
      if (
        question.default_value &&
        question.default_value.decision_tree
      ) {
        const history = questionHistory ? questionHistory : this.getQuestionHistoryByQuestion(questionIndex);
        return await this.decisionTreeService.computeDecisionTree(
          question.default_value.decision_tree,
          FormUtils.getValidValues(this.state.form ? this.state.form.questions : [], this.state.values, history, questionIndex)
        );
      }
    }
    return null;
  }

  async computeConditionalInfo(questionIndex: number, questionHistory?: number[]) {
    const question = this.state.form?.questions[questionIndex];
    if (question) {
      let result = '';
      // If empty conditionalInfo return empty string
      if (!question.conditionalInfo || (Array.isArray(question.conditionalInfo) && question.conditionalInfo.length === 0)) {
        return result;
      }

      const history = questionHistory ? questionHistory : this.getQuestionHistoryByQuestion(questionIndex);
      const cache = new Map();
      const values = FormUtils.getValidValues(this.state.form ? this.state.form.questions : [], this.state.values, history, questionIndex);

      for (const conditionalInfo of question.conditionalInfo) {
        if (await FormUtils.checkCondition(
          this.decisionTreeService,
          conditionalInfo.conditions.lhs,
          conditionalInfo.conditions.operator,
          conditionalInfo.conditions.rhs,
          values,
          cache
        )) {
          if (result !== '') {
            result += '<br/>';
          }
          result += this.formService.t(this.t, conditionalInfo.info, true, false, true);
        }
      }

      return result;
    }

    return '';
  }

  /**
   * Handle: onSubmitForm
   *
   * Reset the form
   */
  handleSubmitForm(product: Product) {
    this.setState({
      summary: false,
      product: product,
    });
  }

  /**
   * Handle: onStepClick (FormStepper) & onQuestionClick (FormSummary)
   *
   * Navigate to the desired question
   */
  handleQuestionClick(questionId: number, fromSummary: boolean = false) {
    // if summary
    if (questionId === -1) {
      this.gotoSummary();
      return;
    }

    Promise.all([
      this.computeDefaultValue(questionId),
      this.computeConditionalInfo(questionId),
    ])
      .then((data: any) => {
        this.setState({
          currentQuestion: questionId,
          displaySummaryStep: this.state.displaySummaryStep || fromSummary,
          summary: false,
          defaultValue: data[0],
          conditionalInfo: data[1],
        });
      });
  }

  /**
   * Get value for a question
   */
  getValue(type: QuestionType, question: string) {
    const answers = this.state.values;
    if (!answers || answers[question] === undefined) {
      switch (type) {
        case QuestionType.NUMBER:
          return 0;
        default:
          return null;
      }
    }
    return answers[question];
  }

  /**
   * Get value for the current question
   */
  getCurrentValue() {
    const question = this.state.form?.questions[this.state.currentQuestion];
    if (!question) {
      return null;
    }
    const currentValue = this.getValue(question.type, question.question);

    if (question.type === QuestionType.NUMBER && currentValue === 0) {
      return null;
    }
    return currentValue;
  }

  /**
   * Create question elements
   */
  createElement(
    question: FormQuestion,
    value: any,
    handleValueChange: (v: any, f?: boolean) => void
  ): ReactNode {
    switch (question.type) {
      case QuestionType.RADIO_IMAGE:
        return (
          <FormRadioImage
            currentValue={value}
            images={question.imageValues}
            values={question.values}
            onValueChange={(v) => handleValueChange(v, true)}
          />
        );
      case QuestionType.RADIO:
        return (
          <FormRadioList
            name={this.getQuestionLabel(question.question)}
            currentValue={value}
            values={question.values}
            onValueChange={(v) => handleValueChange(v, true)}
          />
        );
      case QuestionType.DROPDOWN:
        return (
          <FormDropdown
            name={this.getQuestionLabel(question.question)}
            currentValue={value}
            values={question.values}
            onValueChange={(v) => handleValueChange(v, true)}
          />
        );
      case QuestionType.MATRIX:
        return (
          <FormMatrix
            currentValue={value}
            images={question.imageValues}
            values={question.values}
            onValueChange={(v) => handleValueChange(v, true)}
          />
        );
      case QuestionType.BOOL:
        return (
          <FormBool
            name={this.getQuestionLabel(question.question)}
            currentValue={value}
            onValueChange={(v) => handleValueChange(v, true)}
          />
        );
      case QuestionType.NUMBER:
        return (
          <FormInputNumber
            question={question}
            value={value}
            onValueChange={(v) => handleValueChange(v)}
            onBlur={(question, v) => {
              if (question !== this.state.form?.questions[this.state.currentQuestion].question) {
                return;
              }
              return handleValueChange(v)
            }}
          />
        );
      case QuestionType.LONG_TEXT:
        return (
          <FormLongText
            question={question}
            value={value}
            onValueChange={(v) => handleValueChange(v)}
          />
        );
    }
  }

  /**
   * Check the conditions and return the next question
   */
  async getNextQuestion(
    index: number,
    questions: FormQuestion[],
  ): Promise<{ nextQuestion: number, lastQuestion: number, questionHistory: number[] }> {
    if (!this.state.form) {
      return {
        nextQuestion: this.state.currentQuestion,
        lastQuestion: this.state.currentQuestion,
        questionHistory: this.state.questionHistory
      };
    }

    const cache = new Map();

    let nextQuestion;
    let lastQuestion;
    let questionHistory = this.state.questionHistory.slice(0);
    if (!questionHistory.includes(this.state.currentQuestion)) {
      questionHistory.push(this.state.currentQuestion);
    }

    for (let i = index + 1; i < questions.length; i++) {
      let values = FormUtils.getValidValues(this.state.form.questions, this.state.values, questionHistory, i);

      const question = questions[i];

      if (!question.conditions || await FormUtils.checkCondition(
        this.decisionTreeService,
        question.conditions.lhs,
        question.conditions.operator,
        question.conditions.rhs,
        values,
        cache
      )) {
        if (!nextQuestion) {
          nextQuestion = i;
        }
        lastQuestion = i;
        if (question.isValid && this.state.values[question.question] !== undefined) {
          if (!questionHistory.includes(i)) {
            questionHistory.push(i);
          }
          continue;
        }
        return {nextQuestion, lastQuestion, questionHistory};
      }
    }

    return {
      nextQuestion: this.state.currentQuestion,
      lastQuestion: this.state.currentQuestion,
      questionHistory: questionHistory
    };
  }

  getQuestionLabel(key: string): string {
    return this.t(`QUESTION.LABEL.${key}`);
  }

  render() {
    if (!this.state.form) {
      return <div>Loading...</div>;
    }

    if (!this.state.productionStarted) {
      return (
        <React.Fragment>
          <SynchronizeButton synchronizeFunction={() => this.synchronizeStorageProduction()}/>
          <Toast ref={(el) => (this.toast = el)}/>
          <StartProduction
            startProd={() => {
              this.useSaveState = true;
              this.setState({summary: false, displaySummaryStep: false, productionStarted: true});
            }}
            continueProd={() => this.useSavedState()}
            canContinue={this.canContinue()}
          />
        </React.Fragment>
      );
    }

    //
    // = COIL SELECTION
    //
    if (!this.state.coil || this.state.showCoilReference) {
      return (
        <React.Fragment>
          <SynchronizeButton synchronizeFunction={() => this.synchronizeStorageProduction()}/>
          <Toast ref={(el) => (this.toast = el)}/>
          <CoilSearch
            coil={this.state.coil}
            callBack={(coil: Coil) => {
              this.useSaveState = true;
              this.setState({coil: coil, showCoilReference: false});
            }}
            returnCallBack={() => this.setState({productionStarted: false})}
          />
        </React.Fragment>
      );
    }

    //
    // = COIL WEIGHT
    //
    if (!this.state.coilWeight || this.state.showCoilWeight) {
      return (
        <React.Fragment>
          <SynchronizeButton synchronizeFunction={() => this.synchronizeStorageProduction()}/>
          <Toast ref={(el) => (this.toast = el)}/>
          <CoilWeight
            coil={this.state.coil}
            coilWeight={this.state.coilWeight}
            callBack={(weight: number) => {
              this.useSaveState = true;
              this.setState({coilWeight: weight, showCoilWeight: false});
            }}
            returnCallBack={() => this.setState({showCoilReference: true})}
          />
        </React.Fragment>
      );
    }

    //
    // = products list
    //
    if (this.state.displayProductsList) {
      return (
        <React.Fragment>
          <SynchronizeButton synchronizeFunction={() => this.synchronizeStorageProduction()}/>
          <Toast ref={(el) => (this.toast = el)}/>
          <ProductsList products={this.state.products}
                        callBack={() => {
                          this.useSaveState = true;
                          this.setState({productsValidated: true, displayProductsList: false});
                        }}
                        addCallBack={() => this.handleReset()}
                        returnCallBack={() => this.setState({showCoilWeight: true})}
                        editCallBack={(item: ProductListItem) => {
                          this.setState({editKey: item.key, summary: true, product: null, displayProductsList: false});
                        }}
                        deleteCallBack={(item: ProductListItem | undefined) => {
                          if (item){
                            this.setState({products: this.state.products.filter(f => f.key !== item.key)});
                          }
                        }}
          />
        </React.Fragment>
      );
    }

    //
    // = Coil weight after production
    //
    if (this.state.productsValidated) {
      return (
        <React.Fragment>
          <SynchronizeButton synchronizeFunction={() => this.synchronizeStorageProduction()}/>
          <Toast ref={(el) => (this.toast = el)}/>
          <CoilWeightAfterProduction coilWeight={this.state.coilWeightAfterProduction}
                                     coilWeightBeforeProduction={this.state.coilWeight}
                                     products={this.state.products}
                                     callBack={(weight: number) => this.sendSubmission(weight)}
                                     returnCallBack={() => this.setState({
                                       productsValidated: false,
                                       displayProductsList: true
                                     })}
                                     coil={this.state.coil}
          />
        </React.Fragment>
      );
    }

    //
    // = SUMMARY
    //
    if (this.state.summary) {
      let prodLength : number | null = null;
      let prodQuantity : number | null = null;
      let prodWidth : number | null = null;
      let prodThickness : number | null = null;
      let order : string | null = null;
      if (this.state.editKey !== null) {
        const prod = this.state.products.find(f => f.key === this.state.editKey);
        if (prod) {
          prodLength = prod.product.length;
          prodQuantity = prod.product.quantity;
          prodWidth = prod.product.width;
          prodThickness = prod.product.thickness;
          order = prod.product.order;
        }
      }
      return (
        <React.Fragment>
          <SynchronizeButton synchronizeFunction={() => this.synchronizeStorageProduction()}/>
          <Toast ref={(el) => (this.toast = el)}/>
          <FormSummary
            form={this.state.form}
            history={this.state.questionHistory}
            values={FormUtils.getValidValues(this.state.form.questions, this.state.values, this.state.questionHistory, this.state.currentQuestion, true)}
            onSubmitForm={(product: Product) => {
              this.handleSubmitForm(product);
              let stateSave = JSON.parse(JSON.stringify(this.state))
              if (stateSave) {
                stateSave.products = null;
              }
              const item = new ProductListItem(
                this.state.editKey ?? (this.state.products.length > 0 ? (this.state.products[this.state.products.length - 1].key + 1) : 0),
                stateSave,
                product
              );
              if (this.state.editKey !== null) {
                const prodIndex = this.state.products.findIndex(f => f.key === this.state.editKey);
                if (prodIndex > -1) {
                  let items = [...this.state.products];
                  items[prodIndex] = item;
                  this.setState({products: items});
                }
              } else {
                this.state.products.push(item);
              }
              this.useSaveState = true;
              this.setState({displayProductsList: true, editKey: null});
            }}
            onPreviousClick={() => this.handlePreviousClick(true)}
            onQuestionClick={(questionId: number) => {
              if (questionId === null || questionId === undefined) {
                return;
              }
              this.handleQuestionClick(questionId, true)
            }}
            length={prodLength}
            quantity={prodQuantity}
            width={prodWidth}
            thickness={prodThickness}
            order={order}
          />
        </React.Fragment>
      );
    }

    //
    // = FORM
    //
    const question = this.state.form.questions[this.state.currentQuestion];
    const el: ReactNode = this.createElement(
      question,
      this.getValue(question.type, question.question),
      this.handleValueChange.bind(this)
    );

    // Image
    let imageNodes: ReactNode[] = [];

    if (question.images.length) {
      for (let i = 0; i < question.images.length; i++) {
        const image = question.images[i];
        imageNodes.push(
          <div key={"image-" + i} className="p-col p-d-flex p-jc-center">
            <ImageWrapper src={image}
                          alt={this.formService.t(this.t, question.question, false)}/>
          </div>
        );
      }
    }

    return (
      <React.Fragment>
        <Toast ref={(el) => (this.toast = el)}/>
        {/*<FormFeedback/>*/}
        <FormStepper
          form={this.state.form}
          currentQuestion={this.state.currentQuestion}
          displaySummaryStep={this.state.displaySummaryStep}
          history={this.state.questionHistory}
          values={this.state.values}
          onStepClick={(questionId: number) =>
            this.handleQuestionClick(questionId)
          }
        />
        <div className="question-container">
          <label>{this.formService.t(this.t, this.getQuestionLabel(question.question), true)}</label>
          <div id={question.question}>
            {question.info && (
              <div
                className="form-question-info"
              >{this.formService.t(this.t, question.info, true)}</div>
            )}
            {this.state.conditionalInfo && this.state.conditionalInfo !== '' && (
              <div
                className="form-question-info"
                dangerouslySetInnerHTML={{__html: this.state.conditionalInfo}}
              ></div>
            )}
            <div className="grid align-center">
              <div
                className={imageNodes.length ? "col-12 md-6" : "col-12"}
              >
                <div className="form-question-input">
                  {question.default_value && this.state.defaultValue && (
                    <div className="form-question-suggestion">
                      {this.t("UI.INPUT.SUGGESTION")}
                      {this.state.defaultValue}
                      {question.unit && (
                        <React.Fragment> {question.unit}</React.Fragment>
                      )}
                    </div>
                  )}
                  {el}
                </div>
              </div>
              {imageNodes.length ? (
                <div className="col-12 md-6">
                  <div className="grid">{imageNodes}</div>
                </div>
              ) : null}
            </div>
            {question.subInfo && (
              <div
                className="form-question-sub-info"
              >{this.formService.t(this.t, question.subInfo, true)}</div>
            )}
          </div>
        </div>
        <div className="form-actions-wrapper">
          <SynchronizeButton synchronizeFunction={() => this.synchronizeStorageProduction()}/>
          <div className="left-actions">
            {!this.state.waitValue ? (
              <Button
                icon="pi pi-chevron-left"
                iconPos="left"
                label={this.t("UI.BUTTON.PREVIOUS")}
                className={"secondary"}
                onClick={() => this.handlePreviousClick()}
              />
            ) : undefined}
          </div>
          <div className="right-actions">
            {!this.isLastQuestion && this.state.questionHistory.length !== 0 ? (
              <Button
                icon="pi pi-step-forward-alt"
                iconPos="right"
                label={this.t("UI.BUTTON.GO_TO_LAST")}
                onClick={() => this.handleNextClick(true)}
              />
            ) : undefined}
            {this.hasNextPage ? (
              <Button
                icon="pi pi-chevron-right"
                iconPos="right"
                label={this.t("UI.BUTTON.NEXT")}
                onClick={() => this.handleNextClick()}
                disabled={!this.canSubmitQuestion}
              />
            ) : undefined}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(withRouter(FormPage));
