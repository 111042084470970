import { TFunction } from "i18next";
import React, { Component, ReactNode } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import "./FormMatrix.scss";
import { FormService } from "../services/FormService";

interface Props extends WithTranslation {
  currentValue: string;
  values: string[];
  images: string[];
  onValueChange: (v: string) => void;
}

interface States {
  value: string;
}

class FormMatrix extends Component<Props, States> {
  private t: TFunction;
  private readonly service: FormService;

  constructor(props: Props) {
    super(props);
    this.service = new FormService();
    this.t = this.props.t;

    this.state = {
      value: this.props.currentValue,
    };
  }

  /**
   * Handle: onClick on matrix-wrapper
   */
  handleClick(value: string) {

    this.props.onValueChange(value);

    this.setState({
      value,
    });
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<States>, snapshot?: any) {
    if (this.props.currentValue !== this.state.value) {
      this.setState({
        value: this.props.currentValue
      });
    }
  }

  render() {
    let imageList: ReactNode[] = [];

    for (let i = 0; i < this.props.values.length; i++) {
      const value = this.props.values[i];
      const image = this.props.images[i];

      let className = "matrix-wrapper col-6 md-4";

      if (this.state.value === value) {
        className += " matrix-selected";
      }

      imageList.push(
        <div
          key={value}
          className={className}
          onClick={() => this.handleClick(value)}
        >
          <div
            className="matrix-label"
            style={{ backgroundImage: `url(${image})` }}
          >
            {this.service.checkIfValue(this.t(`RESPONSES.${value}`), value)}
          </div>
        </div>
      );
    }
    return <div className="matrix-container grid">{imageList}</div>;
  }
}

export default withTranslation()(FormMatrix);
