import { TFunction } from "i18next";
import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { FormService } from "../services/FormService";
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';

interface Props extends WithTranslation {
  name: string;
  currentValue: string;
  values: string[];
  onValueChange: (v: string) => void;
}

class FormDropdown extends Component<Props> {
  private t: TFunction;
  private formService: FormService;

  constructor(props: Props) {
    super(props);
    this.t = props.t;
    this.formService = new FormService();
  }

  render() {
    let options: { id: string; name: string }[] = [];

    for (const value of this.props.values) {
      options.push({
        id: value,
        name: this.t(value),
      });
    }

    return (
      <Dropdown
        value={this.props.currentValue}
        options={options}
        optionLabel="name"
        optionValue="id"
        placeholder={this.t("UI.PLACEHOLDER.SELECT_VALUE")}
        onChange={(e: DropdownChangeParams) => {
          if (e.target.value !== this.props.currentValue) {
            this.props.onValueChange(e.target.value);
          }
        }}
      />
    );
  }
}

export default withTranslation()(FormDropdown);
