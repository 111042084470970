export class ProductionSearchFieldsDTO {

  constructor(
    readonly coilReference: string[] | null,
    readonly productReference: string[] | null,
    readonly startDate: Date | Date[] | undefined,
    readonly endDate: Date | Date[] | undefined,
    readonly sentToMSoft: boolean | null,
  ) {
  }
}
