import { ResponsesDTO } from "../dto/ResponsesDTO";
import { DecisionTree } from "../../admin/models/DecisionTree";

export class DecisionTreeUtils {
  static process(tree: DecisionTree | null, responses: ResponsesDTO): string {
    if (!tree) {
      return '';
    }

    const rootNode = tree.tree.find(f => f.root);
    if (!rootNode) {
      return '';
    }

    let currentNode : any = rootNode;
    for (const answer of responses.responses) {
      const branching = currentNode.branches.find(f => f.answer === answer.value.toString());
      if (!branching){
        return '';
      }

      currentNode = tree.tree.find(f => f.nodeName === branching.nodeName);
    }

    return currentNode.value;
  }

}
