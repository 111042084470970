import { TFunction } from "i18next";
import { Toast } from "primereact/toast";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { AdminUsersService } from "../services/AdminUsersService";
import { User } from "../models/User";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { RoleEnum } from "../enum/RoleEnum";

interface Props extends WithTranslation {
  modalVisible: boolean;
  displayModal: (show: boolean) => void;
  updateAction: (user: User) => void;
  users: User[],
}

interface States {
}

class AdminUsers extends React.Component<Props, States> {
  private readonly adminUsersService: AdminUsersService;
  private toast: Toast | null;
  private t: TFunction;

  constructor(props: Props) {
    super(props);
    this.adminUsersService = new AdminUsersService();
    this.t = this.props.t;
    this.toast = null;

    this.state = {
    }
  }

  actionBodyTemplate(rowData: User) {
    return (
      <React.Fragment>
        <Button
          type="button"
          icon="pi pi-pencil"
          className="p-button-secondary p-mr-1"
          onClick={() => {this.props.updateAction(rowData)}}
        />
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Toast ref={(el) => (this.toast = el)}/>
        <Card title={this.t("UI.ADMIN.USERS.TITLE")}>
          <Button
            label={this.t("UI.ADMIN.USERS.ADD")}
            className="mb-2"
            onClick={() => { this.props.displayModal(true) }}
          />
          <DataTable
            value={this.props.users}
            rows={5}
            paginator={true}
            rowsPerPageOptions={[5, 10, 25]}
          >
            <Column
              body={(rowData: User) =>
                rowData.role === RoleEnum.ADMIN ? 'A' : ''
              }
              header={"" /*this.t("UI.ADMIN.USERS.ADMIN")*/}
              headerStyle={{ width: "2rem" }}
              bodyStyle={{ textAlign: "start" }}
            />
            <Column
              field="email"
              filter
              filterPlaceholder={this.t("UI.ADMIN.DECISION_TREE.SEARCH_BY_NAME")}
              header={this.t("UI.ADMIN.USERS.NAME")}
            />
            <Column
              body={(rowData: User) =>
                this.actionBodyTemplate(rowData)
              }
              headerStyle={{ width: "8rem" }}
              bodyStyle={{ textAlign: "center" }}
            />
          </DataTable>
        </Card>
      </React.Fragment>
    );
  }
}

export default withTranslation()(AdminUsers);
