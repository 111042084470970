import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import "./SwitchModeButton.scss";
import { Button } from 'primereact/button';
import { TFunction } from 'i18next';
import { useHistory } from 'react-router-dom';
import { AuthService } from '../../login/services/AuthService';

interface Props extends WithTranslation {
}

function SwitchModeButton(props: Props) {
  const history = useHistory();
  const t = props.t as TFunction;
  const isInAdmin = history?.location.pathname === '/admin';

  const goToAdmin = () => {
    history.push("/admin");
  }

  const goToUser = () => {
    history.push("/");
  }

  const switchMode = () => {
    if (isInAdmin){
      goToUser();
    }
    else{
      goToAdmin();
    }
  }

  return (
    <div className="text-center" style={AuthService.isAdmin ? { display: "normal" } : { display: "none" }}>
      <Button className="button-switch"
              icon="pi pi-user"
              iconPos="left"
              type="button"
              label={isInAdmin ? t("UI.BUTTON.SWITCH_TO_USER") : t("UI.BUTTON.SWITCH_TO_ADMIN")}
              onClick={() => {
        switchMode();
      }}/>
    </div>
  );
}

export default withTranslation()(SwitchModeButton);
