import { ProductionItem } from "./ProductionItem";

export class ProductionLine {
  constructor(
    readonly _id: string,
    readonly coilReference: string,
    readonly userID: string,
    readonly weightCoilBefore: number | null,
    readonly weightBefore: number | null,
    readonly weightBeforeDelta: number | null,
    readonly weightTheorical: number | null,
    readonly weightAfter: number | null,
    readonly weightProductsDelta: number | null,
    readonly date: Date,
    readonly sentToMSoft: Boolean,
    readonly products: ProductionItem[],
  ) {
  }
}
