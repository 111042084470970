import i18n from "i18next";
import BrowserDetector from "i18next-browser-languagedetector";
import HttpApi, { BackendOptions } from "i18next-http-backend";
import React, { Component } from "react";
import { initReactI18next, WithTranslation, withTranslation, } from "react-i18next";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AdminPage from "./admin/AdminPage";
import FormPage from "./form/FormPage";
import MainLayout from "./layout/MainLayout";
import AdminRoute from "./login/components/AdminRoute";
import PrivateRoute from "./login/components/PrivateRoute";
import LoginPage from "./login/LoginPage";

i18n
  .use(initReactI18next)
  .use(BrowserDetector)
  .use(HttpApi)
  .init({
    lng: "fr",
    fallbackLng: "fr",
    backend: {
      loadPath: `${process.env.REACT_APP_API_URL}/translations/{{lng}}`,
    } as BackendOptions,
  });

interface Props extends WithTranslation {
}

class App extends Component<Props> {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route path="/login">
              <LoginPage/>
            </Route>
            <AdminRoute path="/admin">
              <MainLayout>
                <AdminPage/>
              </MainLayout>
            </AdminRoute>
            <PrivateRoute path="/">
              <MainLayout>
                <FormPage/>
              </MainLayout>
            </PrivateRoute>
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default withTranslation()(App);
