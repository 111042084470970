import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import "./SelectLanguage.scss";
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';

interface Props extends WithTranslation {
}

class SelectLanguage extends Component<Props> {
  static languages = ["fr", "nl", "en"];

  handleSelectChange(value: string) {
    this.props.i18n.changeLanguage(value);
  }

  render() {
    const options: { id: string; name: string }[] = [];

    for (const language of SelectLanguage.languages) {
      options.push({
        id: language,
        name: language.toUpperCase(),
      });
    }

    return (
      <div className="select-language">
        <Dropdown
          value={this.props.i18n.language}
          optionLabel="name"
          optionValue="id"
          options={options}
          onChange={(e: DropdownChangeParams) =>
            this.handleSelectChange(e.target.value)
          }
        />
      </div>
    );
  }
}

export default withTranslation()(SelectLanguage);
