import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthService } from '../services/AuthService';

interface Props {
  path: string;
}

export default class AdminRoute extends React.Component<Props> {

  render() {
    const isAdmin = AuthService.isAdmin;

    return (
      <Route path={this.props.path} render={({ location }) => isAdmin
        ? this.props.children
        : <Redirect to={{ pathname: '/', state: { from: location } }}/>}/>
    );
  }
}
