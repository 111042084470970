import { TFunction } from 'i18next';

export enum UnitType {
  METER = "m",
  CENTIMETER = 'cm',
  DECIMETER = 'dm',
  MILLIMETER = 'mm',
  TON = 'T',
  KILOGRAM = 'kg',
}

export enum DefaultUnit {
  WEIGHT = UnitType.KILOGRAM,
  LENGTH = UnitType.MILLIMETER,
  THICKNESS = UnitType.MILLIMETER,
  WIDTH = UnitType.MILLIMETER,
}

export function GetSuffix(unit: DefaultUnit): string {
  return ` ${unit.toString()}`;
}

export function GetPlaceHolderSuffix(unit: DefaultUnit, t: TFunction): string {
  return ` ${t("UI.COIL_SEARCH.COIL_FORM.IN")} ${unit}`;
}
