import React, { Component } from "react";
import Logo from "../shared/components/Logo";
import "./MainLayout.scss";
import SelectLanguage from "../shared/components/SelectLanguage";
import LogoutButton from '../login/components/LogoutButton';
import SwitchModeButton from '../shared/components/SwitchModeButton';

interface Props {
}

class MainLayout extends Component<Props> {
  render() {
    return (
      <React.Fragment>
        <header className="layout-header">
          <Logo/>
          <div className="right">
            <SwitchModeButton/>
            <LogoutButton/>
            <SelectLanguage/>
          </div>
        </header>
        <main className="layout-content container">{this.props.children}</main>
      </React.Fragment>
    );
  }
}

export default MainLayout;
