import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import "./CoilSearch.scss";
import { DefaultUnit, UnitType } from '../enum/UnitType';

interface Props extends WithTranslation {
  label: string;
  data: string;
  boldData?: boolean;
  unit?: DefaultUnit | UnitType;
}

interface States {
}

class ShowData extends React.Component<Props, States> {

  GetUnitDisplay(): string {
    if (!this.props.unit || !this.props.data){
      return "";
    }
    else{
      return ` ${this.props.unit}`;
    }
  }

  render() {
    return (
      <div className="flex show-data-container">
        <div className="col-6 flex justify-content-end">
          <label className="align-self-center">
            {this.props.label}:
          </label>
        </div>
        {this.props.boldData && (
          <div className="col-6 flex justify-content-start">
            <label className="align-self-center">
              {this.props.data}{this.GetUnitDisplay()}
            </label>
          </div>
        )}
        {!this.props.boldData && (
          <div className="col-6 flex justify-content-start">
                        <span className="align-self-center">
                            {this.props.data}{this.GetUnitDisplay()}
                        </span>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(ShowData);
