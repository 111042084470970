// Storage management
export const DataStorage = sessionStorage;
export const KEY_ACCESS_TOKEN = 'access_token';

// Offline storage management
export const OfflineDataStorage = localStorage;
export const KEY_OFFLINE_STATE = 'offline_state';
export const KEY_OFFLINE_PRODUCT_LISTS = 'product_lists';
export const KEY_OFFLINE_PRODUCTS_DECISION_TREE = 'products_decision_tree';
export const KEY_OFFLINE_PRODUCTS_DENSITY_MAPPING = 'products_decision_mapping';
export const KEY_OFFLINE_COILS_LIST = 'coils_list';

// General
export const INPUT_NUMBER_MAX_WIDTH = "250px";
export const WEIGHT_DIFFERENCE_TOLERANCE = 2; // percent value
