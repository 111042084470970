import { TFunction } from "i18next";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { INPUT_NUMBER_MAX_WIDTH } from '../../shared/constants';
import { DefaultUnit, GetPlaceHolderSuffix, GetSuffix } from '../enum/UnitType';
import { Toast } from 'primereact/toast';
import { SeverityEnum } from '../../shared/enum/SeverityEnum';
import { ProductListItem } from '../models/ProductListItem';
import { Coil } from "../models/Coil";
import { WeightUtils } from '../utils/WeightUtils';

interface Props extends WithTranslation {
  coilWeightBeforeProduction: number | null;
  coilWeight: number | null;
  products: ProductListItem[];
  coil: Coil;
  callBack: (weight: number) => void;
  returnCallBack: () => void;
}

interface States {
  coilWeight: number | null;
  weightDiscrepency: boolean;
}

class CoilWeightAfterProduction extends React.Component<Props, States> {
  private t: TFunction;
  private toast: Toast | null;

  constructor(props: Props) {
    super(props);
    this.t = this.props.t;
    this.toast = null;

    this.state = {
      coilWeight: this.props.coilWeight,
      weightDiscrepency: false,
    };
  }

  checkToleranceThenNext(): void {
    if (this.state.weightDiscrepency || WeightUtils.isDifferenceTolerable(this.props.products, this.props.coil, this.props.coilWeightBeforeProduction ?? 0, this.state.coilWeight ?? 0)) {
      this.props.callBack(this.state.coilWeight ?? 0);
    } else {
      this.toast?.show({
        severity: SeverityEnum.WARN,
        detail: this.t("UI.TOAST_ERROR.WEIGHT_TOLERANCE_EXCEEDED"),
        sticky: true,
      });
      this.setState({ weightDiscrepency: true });
    }
  }


  render() {
    return (
      <div className="coil-weight-container grid flex align-content-center" style={{ minHeight: "60vh" }}>
        <Toast style={{ fontSize: "25px", width: "400px" }} position="bottom-center" ref={(el) => (this.toast = el)}/>
        <div className="col-12 grid flex justify-content-center">
          <label htmlFor="coil-weight" className="col-10 justify-content-center text-center">
            {this.t("UI.COIL_WEIGHT_AFTER_PROD.WEIGHT")}
          </label>
          <InputNumber id="coil-weight" className="col-10 justify-content-center" min={0} inputStyle={{ maxWidth: INPUT_NUMBER_MAX_WIDTH }}
                       onChange={(e) => this.setState({ coilWeight: e.value })} value={this.state.coilWeight ?? undefined}
                       placeholder={`${this.t("UI.COIL_SEARCH.COIL_FORM.WEIGHT")}${GetPlaceHolderSuffix(DefaultUnit.WEIGHT, this.t)}`}
                       suffix={GetSuffix(DefaultUnit.WEIGHT)} onBlur={() => this.setState({ weightDiscrepency: false })}
                       mode="decimal" inputMode="decimal" minFractionDigits={1} maxFractionDigits={2}
          />
        </div>
        <Button onClick={() => this.props.returnCallBack()}
                icon="pi pi-chevron-left"
                iconPos="left"
                label={this.t("UI.BUTTON.PREVIOUS")}
                className="left-actions"/>
        <Button onClick={() => this.checkToleranceThenNext()}
                icon="pi pi-chevron-right"
                iconPos="right"
                label={this.state.weightDiscrepency ? this.t("UI.COIL_WEIGHT_AFTER_PROD.CONFIRM_BUTTON") : this.t("UI.COIL_WEIGHT_AFTER_PROD.VALID_BUTTON")}
                className="right-actions" disabled={this.state.coilWeight === null}/>
      </div>
    );
  }
}

export default withTranslation()(CoilWeightAfterProduction);
