import { TFunction } from "i18next";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import "./CoilSearch.scss";
import { Button } from 'primereact/button';
import { Coil } from '../models/Coil';
import { CoilService } from '../services/CoilService';
import { Toast } from 'primereact/toast';
import ShowData from "./ShowData";
import { DefaultUnit } from '../enum/UnitType';
import { Panel } from "primereact/panel";
import { Dropdown } from "primereact/dropdown";

interface Props extends WithTranslation {
  coil: Coil | null;
  callBack: (coil: Coil) => void;
  returnCallBack: () => void;
}

interface States {
  coil: Coil | null;
  coils: Coil[];
}

class CoilSearch extends React.Component<Props, States> {
  private t: TFunction;
  private coilService: CoilService;
  private toast: Toast | null;

  constructor(props: Props) {
    super(props);
    this.t = this.props.t;
    this.coilService = new CoilService();
    this.toast = null;

    this.state = {
      coil: this.props.coil,
      coils: [],
    };
  }

  async componentDidMount() {
    this.coilService.getAll()
      .then(t => {
        this.setState({coils: t});
      });
    this.coilService.updateCoilsStorage();
  }

  // async searchCoil() {
  //   return this.coilService
  //     .searchCoil(this.state.coilReference)
  //     .then((response: AxiosResponse<Coil>) => {
  //       this.setState({coil: response.data});
  //       return true;
  //     })
  //     .catch((error: AxiosError<Coil>) => {
  //       if (error.response) {
  //         this.toast?.show({
  //           severity: SeverityEnum.ERROR,
  //           detail: this.t(error.response.status === 404 ? "UI.TOAST_ERROR.NOT_FOUND" : "UI.TOAST_ERROR.ERROR"),
  //         });
  //       }
  //       this.setState({coil: null});
  //       return false;
  //     });
  // }

  render() {
    return (
      <div className="coil-search-container grid flex align-content-start"
           style={{marginTop: "50px"}}>
        <Toast ref={(el) => (this.toast = el)}/>
        <div className="col-12 grid flex justify-content-center" style={{maxHeight: "155px"}}>
          <label htmlFor="coil-search" className="col-10">
            {this.t("UI.COIL_SEARCH.REFERENCE")}
          </label>
          <Dropdown className="col-10"
                    value={this.state.coil}
                    options={this.state.coils}
                    onChange={(e) => {
                      this.setState({coil: e.value});
                    }}
                    optionLabel="reference"
                    filter showClear filterBy="reference"
                    placeholder={this.t("UI.COIL_SEARCH.SEARCH_BUTTON")}
          />
          {/*<InputText id="coil-search" className="col-10" value={this.state.coilReference}*/}
          {/*           onChange={(e) => this.setState({coilReference: (e.target as any).value})}*/}
          {/*           placeholder={this.t("UI.COIL_SEARCH.COIL_FORM.REFERENCE")}*/}
          {/*/>*/}
          {/*<Button onClick={() => this.searchCoil()} style={{marginTop: "1rem"}}*/}
          {/*        icon="pi pi-search"*/}
          {/*        iconPos="left"*/}
          {/*        label={this.t("UI.COIL_SEARCH.SEARCH_BUTTON")}*/}
          {/*        disabled={this.state.coilReference === ""}/>*/}
        </div>
        <div className="col-12 grid flex justify-content-center">
          {this.state.coil && (
            <Panel style={{width: "100vw"}}>
              <ShowData label={this.t("UI.COIL_SEARCH.COIL_FORM.REFERENCE")}
                        data={this.state.coil.reference}/>
              <ShowData label={this.t("UI.COIL_SEARCH.COIL_FORM.MSOFT_REFERENCE")}
                        data={this.state.coil.MSoftReference}/>
              <ShowData label={this.t("UI.COIL_SEARCH.COIL_FORM.COLOR")}
                        data={this.state.coil.color}/>
              <ShowData label={this.t("UI.COIL_SEARCH.COIL_FORM.COMPOSITION")}
                        data={this.state.coil.composition}/>
              <ShowData label={this.t("UI.COIL_SEARCH.COIL_FORM.LENGTH")}
                        data={this.state.coil.length?.toString()} unit={DefaultUnit.LENGTH}/>
              <ShowData label={this.t("UI.COIL_SEARCH.COIL_FORM.WIDTH")}
                        data={this.state.coil.width?.toString()} unit={DefaultUnit.WIDTH}/>
              <ShowData label={this.t("UI.COIL_SEARCH.COIL_FORM.THICKNESS")}
                        data={this.state.coil.thickness?.toString()} unit={DefaultUnit.THICKNESS}/>
              <ShowData label={this.t("UI.COIL_SEARCH.COIL_FORM.WEIGHT")}
                        data={this.state.coil.weight?.toString()} unit={DefaultUnit.WEIGHT}/>
            </Panel>
          )}
        </div>
        <Button onClick={() => this.props.returnCallBack()}
                icon="pi pi-chevron-left"
                iconPos="left"
                label={this.t("UI.BUTTON.PREVIOUS")}
                className="left-actions"/>
        <Button onClick={() => this.props.callBack(this.state.coil ?? new Coil("null", "null", 0, 0, 0, "null", 0, "null"))}
                icon="pi pi-chevron-right"
                iconPos="right"
                label={this.t("UI.COIL_SEARCH.VALID_BUTTON")}
                className="right-actions" disabled={!this.state.coil}/>
      </div>
    );
  }
}

export default withTranslation()(CoilSearch);
