import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import "./StartProduction.scss";
import { Button } from 'primereact/button';
import { TFunction } from 'i18next';

interface Props extends WithTranslation {
  startProd: () => void;
  continueProd: () => void;
  canContinue: boolean;
}

function StartProduction(props: Props) {
  const t = props.t as TFunction;

  return (
    <div className="start-prod-container text-center align-center">
      <div className="col-12">
        <Button className="button-start-prod" type="button" label={t("UI.BUTTON.START_PRODUCTION")} onClick={() => { props.startProd(); }}
                disabled={props.canContinue}
        />
      </div>
      <div className="col-12">
        <Button className="button-continue-prod" type="button" label={t("UI.BUTTON.CONTINUE_PRODUCTION")} onClick={() => { props.continueProd(); }}
                disabled={!props.canContinue}
        />
      </div>
    </div>
  );
}

export default withTranslation()(StartProduction);
