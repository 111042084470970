export class Coil {

  constructor(
    readonly reference: string,
    readonly MSoftReference: string,
    readonly thickness: number,
    readonly width: number,
    readonly length: number,
    readonly color: string,
    readonly weight: number,
    readonly composition: string,
  ) {
  }
}
