import { TFunction } from "i18next";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { FormQuestion } from "../models/FormQuestion";
import { FormService } from "../services/FormService";
import { InputNumber } from 'primereact/inputnumber';

interface Props extends WithTranslation {
  question: FormQuestion;
  value: any;
  onValueChange: (v: any) => void;
  onBlur: (question: string, v: any) => void;
}

class FormInputNumber extends React.Component<Props> {
  private t: TFunction;
  private formService: FormService;

  state = {
    showValidationMessage: false,
    value: 0
  };

  constructor(props: Props) {
    super(props);
    this.formService = new FormService();
    this.t = this.props.t;

    this.state = {
      showValidationMessage: false,
      value: 0
    };
  }

  componentDidMount() {
    this.setState({ showValidationMessage: false, value: this.resetValueIfInvalid() });

    this.bindOnBlur();
    this.bindKeyup();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
    if (this.props.question.question !== prevProps.question.question) {
      this.bindOnBlur();
      this.bindKeyup();

      this.setState({ showValidationMessage: false, value: this.resetValueIfInvalid() });
    }
  }

  resetValueIfInvalid() {
    if (this.props.question.validation && Number.isInteger(this.props.question.validation.min) && (this.props.value < this.props.question.validation.min)) {
      return 0;
    } else {
      return this.props.value;
    }
  }

  onValueChange(v: any) {
    this.setState({ ...this.state, value: v });
    this.props.onValueChange(v);
  }

  private bindOnBlur() {
    document.getElementById(this.props.question.question)?.addEventListener('blur', () => {
      if (!this.state.showValidationMessage) {
        this.setState({ ...this.state, showValidationMessage: true });
      }
      this.props.onBlur(this.props.question.question, this.state.value);
    });
  }

  private bindKeyup() {
    document.getElementById(this.props.question.question)?.addEventListener('keyup', (e: KeyboardEvent) => {
      if (e.key.toLowerCase() === "enter") {
        this.setState({ ...this.state, showValidationMessage: true });
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="form-input-group">
          <InputNumber
            id={this.props.question.question}
            // min={this.props.question.validation.min}
            min={-999999999999999}
            max={999999999999999}
            mode="decimal" inputMode="decimal" minFractionDigits={1} maxFractionDigits={2}
            value={this.state.value ?? undefined}
            onChange={(e: any) => this.onValueChange(e.target.value)}
          />
          {this.props.question.unit && (
            <div className="form-input-unit">{this.props.question.unit}</div>
          )}
        </div>
        {this.props.question.validation && this.state.showValidationMessage && Number.isInteger(this.props.question.validation.min) && (this.state.value < this.props.question.validation.min) && (
          <div className="form-question-suggestion text-danger text-italic">
            {this.t("UI.INPUT.MIN_VALUE")}
            {this.props.question.validation.min}
            {this.props.question.unit && (
              <React.Fragment> {this.props.question.unit}</React.Fragment>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation()(FormInputNumber);
