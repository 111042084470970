import { APIService } from '../../shared/services/APIService';

export class AdminFormService extends APIService {

  /**
   * Upload and replace the form
   *
   * @role Admin
   */
  uploadForm(file: File) {
    const formData = new FormData();
    formData.append('form', file);
    return this.http.put<void>('/forms/upload', formData);
  }

  /**
   * Download the current form
   */
  downloadForm() {
    return this.http.get<Blob>('/forms/download', { responseType: 'blob' });
  }
}