import { APIService } from '../../shared/services/APIService';
import { Coil } from '../models/Coil';
import { FormUtils } from "../utils/FormUtils";
import { AxiosError, AxiosResponse } from "axios";
import {
  KEY_OFFLINE_COILS_LIST,
  OfflineDataStorage
} from "../../shared/constants";

export class CoilService extends APIService {
  getOfflineCoils() {
    let unparsedCurrentList = OfflineDataStorage.getItem(KEY_OFFLINE_COILS_LIST);
    let currentList: Coil[] = [];
    if (unparsedCurrentList && unparsedCurrentList !== 'undefined') {
      currentList = JSON.parse(unparsedCurrentList);
    }
    return currentList;
  }

  async getAll() {
    if (window.navigator.onLine) {
      return this.http.get<Coil[]>('/coil/')
        .then((response: AxiosResponse<Coil[]>) => {
          OfflineDataStorage.setItem(KEY_OFFLINE_COILS_LIST, JSON.stringify(response.data));
          return response.data;
        });
    }
    else {
      return this.getOfflineCoils();
    }
  }

  async searchCoil(reference: string) {
    if (window.navigator.onLine) {
      return this.http.get<Coil>('/coil/get/' + reference);
    }
    else {
      const coils = this.getOfflineCoils();
      let coil = coils.find(f => f.reference === reference);
      if (coil) {
        return FormUtils.createAxiosResponse(coil);
      }
      throw FormUtils.createAxiosNotFound();
    }
  }

  updateCoilsStorage() {
    if (window.navigator.onLine) {
      this.http.get<Coil[]>('/coil')
        .then((response: AxiosResponse<Coil[]>) => {
          OfflineDataStorage.setItem(KEY_OFFLINE_COILS_LIST, JSON.stringify(response.data));
          return true;
        })
        .catch((error: AxiosError<Coil[]>) => {
          return false;
        });
    }
    else {
      return false;
    }

  }
}
