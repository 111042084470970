import React, { Component } from 'react';
import logoImg from '../../assets/logo.png'


interface Props {

}

class Logo extends Component<Props> {
  render() {
    return (
      <img className={"logo"} src={logoImg} alt="AGJ logo"/>
    );
  }
}

export default Logo;
