import { Product } from '../models/Product';

export class ProductionDTO {

  constructor(
    readonly coilReference: string,
    readonly weightCoilBefore: number,
    readonly weightBefore: number,
    readonly weightTheorical: number,
    readonly weightAfter: number,
    readonly products: Product[],
  ) {
  }
}
