import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button } from 'primereact/button';

interface Props extends WithTranslation {
  synchronizeFunction: () => void;
}

function SynchronizeButton(props: Props) {
  return (
    <div className="top-left-actions">
      <Button
        style={{height: "50px", width: "50px"}}
        icon="pi pi-refresh"
        iconPos="left"
        className={"secondary"}
        onClick={() => props.synchronizeFunction()}
      />
    </div>
  );
}

export default withTranslation()(SynchronizeButton);
