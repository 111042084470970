import { TFunction } from "i18next";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import "./FormSummary.scss";
import { ProductListItem } from '../models/ProductListItem';
import { Button } from 'primereact/button';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Panel } from "primereact/panel";
import { Dialog } from 'primereact/dialog';

interface Props extends WithTranslation {
  products: ProductListItem[];
  callBack: () => void;
  addCallBack: () => void;
  returnCallBack: () => void;
  editCallBack: (item: ProductListItem) => void;
  deleteCallBack: (item: ProductListItem | undefined) => void;
}

interface States {
  displayConfirmation: boolean,
  rowData: ProductListItem | undefined,
}

class ProductsList extends React.Component<Props, States> {
  static readonly MAX_PRODUCTS_IN_LIST = 15;
  private t: TFunction;

  constructor(props: Props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      displayConfirmation: false,
      rowData: undefined,
    };
  }

  actionBodyTemplate(rowData: ProductListItem) {
    return (
      <React.Fragment>
        <Button
          type="button"
          icon="pi pi-pencil"
          className="p-button-secondary p-mr-1 datatable-action"
          onClick={() => this.props.editCallBack(rowData)}
        />
        <Button
          type="button"
          icon="pi pi-trash"
          className="p-button-danger datatable-action"
          onClick={() => {
            this.setState({ displayConfirmation: true, rowData: rowData })
          }}
        />
      </React.Fragment>
    );
  }

  renderDialogFooter() {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => this.setState({ displayConfirmation: false })}
          className="p-button-text"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          onClick={() => {
            this.setState({ displayConfirmation: false });
            this.props.deleteCallBack(this.state.rowData);
          }}
          autoFocus
        />
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Dialog
          header={this.t("UI.PRODUCTS_LIST.CONFIRM_DIALOG.TITLE")}
          visible={this.state.displayConfirmation}
          modal
          style={{ width: "350px" }}
          footer={this.renderDialogFooter()}
          onHide={() => this.setState({ displayConfirmation: false })}
        >
          <div className="confirmation-content">
            <span>{this.t("UI.PRODUCTS_LIST.CONFIRM_DIALOG.TEXT")}</span>
          </div>
        </Dialog>
        <div className="flex products-container col-12 grid p-fluid" style={{marginTop: "2rem"}}>
          <Panel className={"col-12"}>
            <DataTable value={this.props.products}
                       rows={5}
                       paginator={true}
                       className={"col-12"}>
              <Column field="product.reference"
                      header={this.t("UI.PRODUCTS_LIST.TABLE.REFERENCE")}/>
              <Column field="product.quantity" header={this.t("UI.PRODUCTS_LIST.TABLE.QUANTITY")}/>
              <Column field="product.length" header={this.t("UI.PRODUCTS_LIST.TABLE.LENGTH")}/>
              <Column field="product.thickness" header={this.t("UI.PRODUCTS_LIST.TABLE.THICKNESS")}/>
              <Column field="product.widthDisplay" header={this.t("UI.PRODUCTS_LIST.TABLE.WIDTH")}/>
              <Column field="product.order" header={this.t("UI.PRODUCTS_LIST.TABLE.ORDER")}/>
              <Column
                body={(rowData: ProductListItem) =>
                  this.actionBodyTemplate(rowData)
                }
                headerStyle={{width: "125px"}}
                bodyStyle={{textAlign: "center"}}
              />
            </DataTable>
          </Panel>
        </div>
        <Button onClick={() => this.props.addCallBack()}
                icon="pi pi-plus"
                iconPos="left"
                label={this.t("UI.PRODUCTS_LIST.ADD_PRODUCT")}
                disabled={this.props.products.length >= ProductsList.MAX_PRODUCTS_IN_LIST}/>
        <Button onClick={() => this.props.returnCallBack()}
                icon="pi pi-chevron-left"
                iconPos="left"
                label={this.t("UI.BUTTON.PREVIOUS")}
                className="left-actions"/>
        <Button onClick={() => this.props.callBack()}
                icon="pi pi-chevron-right"
                iconPos="right"
                label={this.t("UI.PRODUCTS_LIST.VALID_BUTTON")}
                className="right-actions"
                disabled={this.props.products.length === 0}/>
      </React.Fragment>
    );
  }
}

export default withTranslation()(ProductsList);
