import { Messages } from "primereact/messages";
import React from "react";
import Logo from "../shared/components/Logo";
import { SeverityEnum } from "../shared/enum/SeverityEnum";
import ButtonLogin from "./components/ButtonLogin";
import "./LoginPage.scss";
import FormInputText from "../form/components/FormInputText";
import FormInputPassword from "../form/components/FormInputPassword";
import { TFunction } from "i18next";
import { withTranslation, WithTranslation } from "react-i18next";
import SelectLanguage from "../shared/components/SelectLanguage";

interface Props extends WithTranslation {
}

interface States {
  email: string;
  password: string;
}

class LoginPage extends React.Component<Props, States> {
  state: Readonly<States> = {
    email: "",
    password: "",
  };
  private messages: Messages | null;
  private t: TFunction;

  constructor(props: Props) {
    super(props);
    this.messages = null;
    this.t = this.props.t;
  }

  handleLogin(isAuth: boolean) {
    if (!isAuth && this.messages) {
      this.messages.clear();
      this.messages.show({
        severity: SeverityEnum.ERROR,
        closable: false,
        sticky: true,
        detail: this.t("UI.ERROR.INVALID_CREDENTIALS"),
      });
    }
  }

  render() {
    return (

      <div className="container flex align-content-center flex-wrap" style={{ marginTop: "-15vh" }}>
        <div className="login-select-language">
          <SelectLanguage/>
        </div>
        <div className="text-center col-12 align-content-center align-items-center">
          <Logo/>
        </div>
        <div className="flex login-wrapper justify-content-center col-12">
          <div className="login-card">
            <div className="login-content">
              <Messages ref={(el) => (this.messages = el)}/>
              <form className="grid p-formgrid p-fluid">
                <FormInputText
                  value={this.state.email}
                  label={this.t("UI.LOGIN.EMAIL")}
                  placeholder={this.t("UI.LOGIN.EMAIL")}
                  inline={true}
                  littleInlineLabel={false}
                  onChange={(e) => this.setState({email: (e.target as any).value})}
                />
                <FormInputPassword
                  value={this.state.password}
                  label={this.t("UI.LOGIN.PASSWORD")}
                  placeholder={this.t("UI.LOGIN.PASSWORD")}
                  inline={true}
                  littleInlineLabel={false}
                  onChange={(e) => this.setState({password: (e.target as any).value})}
                />
              </form>
              <ButtonLogin
                email={this.state.email}
                password={this.state.password}
                onLogin={(e) => this.handleLogin(e)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(LoginPage);
