import { APIService } from '../../shared/services/APIService';
import { AxiosResponse } from "axios";
import { User } from "../models/User";

export class AdminUsersService extends APIService {

  /**
   * Get all
   */
  getMany(): Promise<AxiosResponse<User[]>> {
    return this.http.get<User[]>('/user');
  }

  /**
   * Post one
   */
  post(data: any): Promise<AxiosResponse<number>> {
    return this.http.post<number>('/user', data);
  }
}
