import { APIService } from '../../shared/services/APIService';

export class AdminTranslationService extends APIService {

  /**
   * Upload and replace the translations
   *
   * @role Admin
   */
  uploadTranslations(file: File) {
    const formData = new FormData();
    formData.append('translations', file);
    return this.http.put<void>('/translations/upload', formData);
  }

  /**
   * Download translations
   */
  downloadTranslations() {
    return this.http.get<Blob>('/translations/download', { responseType: 'blob' });
  }
}