import { Product } from "./Product";

export class ProductListItem {

  constructor(
    readonly key: number,
    readonly formState: any,
    readonly product: Product,
  ) {
  }
}
