import { AxiosResponse } from 'axios';
import { APIService } from '../../shared/services/APIService';
import { DecisionTreeResultDTO } from '../dto/DecisionTreeResultDTO';
import { ResponsesDTO } from '../dto/ResponsesDTO';
import { DecisionTree } from "../../admin/models/DecisionTree";
import {
  KEY_OFFLINE_PRODUCTS_DECISION_TREE,
  KEY_OFFLINE_PRODUCTS_DENSITY_MAPPING,
  OfflineDataStorage
} from "../../shared/constants";

export class DecisionTreeService extends APIService {

  /**
   * Compute decision tree
   */
  async computeDecisionTree(name: string, responses: Record<string, any>) {
    const dto = new ResponsesDTO(responses);
    return this.http.post<DecisionTreeResultDTO>('/decision-trees/' + name + '/compute', dto)
      .then((response: AxiosResponse<DecisionTreeResultDTO>) => {
        return response.data.result;
      });
  }

  /**
   * get products tree
   */
  async getProductsTree() {
    if (window.navigator.onLine) {
      return this.http.get<DecisionTree>('/decision-trees/getProductsDecisionTree')
        .then((response: AxiosResponse<DecisionTree>) => {
          OfflineDataStorage.setItem(KEY_OFFLINE_PRODUCTS_DECISION_TREE, JSON.stringify(response.data));
        });
    }
  }

  /**
   * get weights tree
   */
  async getWeightsTree() {
    if (window.navigator.onLine) {
      return this.http.get<DecisionTree>('/decision-trees/getWeightsDecisionTree')
        .then((response: AxiosResponse<DecisionTree>) => {
          const mapping = {};

          const rootNode = response.data.tree.find(f => f.root);
          if (!rootNode){
            return;
          }
          for (const ans of rootNode.branches){
            const value = response.data.tree.find(f => f.nodeName === ans.nodeName)?.value;
            if (value) {
              mapping[ans.answer] = parseInt(value);
            }
          }

          OfflineDataStorage.setItem(KEY_OFFLINE_PRODUCTS_DENSITY_MAPPING, JSON.stringify(mapping));
        });
    }
  }
}
