import {Password} from "primereact/password";
import React from "react";
import uuidv4 from "./FieldIdGenerator";

interface Props {
  fieldId?: string;
  label: string;
  required?: boolean;
  maxlength?: number;
  placeholder?: string;
  inline?: boolean;
  value: string;
  littleInlineLabel?: boolean;
  onChange: any;
}

class FormPassword extends React.Component<Props> {
  defaultFieldId: string;
  wrapper: any;

  defaultPlaceholder = "";
  defaultInline = false;
  defaultMaxlength = 999999999;
  defaultRequired = false;
  defaultLittleInlineLabel = false;

  constructor(props: Props) {
    super(props);
    this.defaultFieldId = uuidv4();
    this.wrapper = React.createRef();
  }

  render() {
    return (
      (this.props.inline || this.defaultInline) ?
        <div ref={this.wrapper} className="p-field grid p-ai-center z-form-field col-12">
          <div className={(this.props.littleInlineLabel || this.defaultLittleInlineLabel) ? "col-6" : "col-3" }>
            <label className="z-form-field-label" aria-required={this.props.required || this.defaultRequired}>
              {this.props.label}
            </label>
          </div>
          <div className={(this.props.littleInlineLabel || this.defaultLittleInlineLabel) ? "col-6" : "col-9" }>
            <Password id={this.props.fieldId || this.defaultFieldId} type="text" className="p-inputtext-sm" feedback={false}
                       value={this.props.value} placeholder={this.props.placeholder || this.defaultPlaceholder} maxLength={this.props.maxlength || this.defaultMaxlength}
                       onChange={this.props.onChange}>
            </Password>
          </div>
        </div>
        :
        <div ref={this.wrapper} className="p-field col-12">
          <label className="z-form-field-label" aria-required={this.props.required || this.defaultRequired}>
            {this.props.label}
          </label>
          <Password id={this.props.fieldId} type="text" className="p-inputtext-sm" feedback={false}
                     value={this.props.value} placeholder={this.props.placeholder || this.defaultPlaceholder} maxLength={this.props.maxlength || this.defaultMaxlength}
                     onChange={this.props.onChange}>
          </Password>
        </div>
    );
  }
}

export default FormPassword;
