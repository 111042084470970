import { AxiosResponse } from 'axios';
import { APIService } from '../../shared/services/APIService';
import { ResponsesDTO } from '../dto/ResponsesDTO';
import { Form } from '../models/Form';
import { TFunction } from 'i18next';
import { Product } from '../models/Product';
import { ProductionDTO } from '../dto/ProductionDTO';
import { FormUtils } from "../utils/FormUtils";
import {
  KEY_OFFLINE_PRODUCTS_DECISION_TREE,
  KEY_OFFLINE_PRODUCTS_DENSITY_MAPPING
} from "../../shared/constants";
import { DecisionTreeUtils } from "../utils/DecisionTreeUtils";
import { StorageUtils } from "../utils/StorageUtils";
import { ProductionLine } from "../../admin/models/ProductionLine";
import { ProductionSearchFieldsDTO } from "../dto/ProductionSearchFieldsDTO";
import { FileUtils } from '../../shared/utils/FileUtils';

export class FormService extends APIService {

  /**
   * Get form
   */
  getForm(name: string) {
    return this.http.get<Form>('/forms/' + name);
  }

  /**
   * Submit the form responses
   */
  async getProduct(formName: string, dto: ResponsesDTO) {
    if (window.navigator.onLine) {
      return this.http.post<Product>(`/production/${formName}/getProduct`, dto)
        .then((response: AxiosResponse<Product>) => {
          return response.data;
        });
    } else {
      const productRef = DecisionTreeUtils.process(StorageUtils.GetItem(KEY_OFFLINE_PRODUCTS_DECISION_TREE), dto);

      let density = -1;
      const densityMapping = StorageUtils.GetItem(KEY_OFFLINE_PRODUCTS_DENSITY_MAPPING);
      if (densityMapping) {
        density = densityMapping[productRef];
      }

      if (density < 0 || productRef === '') {
        throw FormUtils.createAxiosNotFound();
      }

      return new Product(productRef, density, 0, 0, 0, 0, '/', '');
    }
  }

  /**
   * Generate MSoft production file
   */
  async generateMSoftProduction() {
    return this.http.get<void>(`/production/MSoftFile`)
      .then((response: AxiosResponse<void>) => {
        return response;
      });
  }

  /**
   * Generate MSoft production file
   */
  async getMSoftProductsNumber() {
    return this.http.get<number>(`/production/MSoftNumber`)
      .then((response: AxiosResponse<number>) => {
        return response;
      });
  }

  /**
   * Download production in CSV file
   */
  async downloadCSV(searchFields: ProductionSearchFieldsDTO) {
    return this.http.post<Blob>('/production/export', searchFields, { responseType: 'blob' })
      .then((response: AxiosResponse<Blob>) => {
        FileUtils.downloadFile(response);
      });
  }

  /**
   * Submit production
   */
  async sendSubmission(dto: ProductionDTO) {
    return this.http.post<void>(`/production`, dto)
      .then((response: AxiosResponse<void>) => {
        return response.data;
      });
  }

  /**
   * Update production
   */
  async updateSubmission(dto: ProductionLine) {
    return this.http.post<void>(`/production/update`, dto);
  }

  /**
   * Get poduction lines
   */
  async getProduction(searchFields: ProductionSearchFieldsDTO) {
    return this.http.post<ProductionLine[]>(`/production/search`, searchFields)
      .then((response: AxiosResponse<ProductionLine[]>) => {
        return response;
      });
  }

  checkIfValue(value: any, key: string) {
    return (value) ? value : key;
  }

  t(t: TFunction, key: string, asRichText: boolean, isUI: boolean = false, asStaticMarkup: boolean = false, isURL: boolean = false): any {
    if (isUI || asRichText) {
      return t(key);
    } else if (isURL) {
      const translation = t(key, {returnObjects: true}) as any;
      if (translation.hasOwnProperty('url')) {
        return translation.url;
      } else {
        return null;
      }
    }
  }
}
