import { TFunction } from "i18next";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { FormQuestion } from "../models/FormQuestion";
import { FormService } from "../services/FormService";
import "./FormLongText.scss";
import { InputTextarea } from 'primereact/inputtextarea';

interface Props extends WithTranslation {
  question: FormQuestion;
  value: any;
  onValueChange: (v: any) => void;
}

class FormLongText extends React.Component<Props> {
  private t: TFunction;
  private formService: FormService;

  constructor(props: Props) {
    super(props);
    this.formService = new FormService();
    this.t = this.props.t;
  }

  render() {
    return (
      <React.Fragment>
        <div className="form-input-group">
          <InputTextarea
            id={this.props.question.question}
            value={this.props.value ? this.props.value : ''}
            required={false}
            onChange={(e: any) => this.props.onValueChange(e.target.value)}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(FormLongText);
