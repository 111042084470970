import { TFunction } from 'i18next';
import { QuestionType } from '../enum/QuestionType';
import { Form } from '../models/Form';
import { FormQuestion } from '../models/FormQuestion';
import { OperatorType } from "../enum/OperatorType";
import { DecisionTreeService } from '../services/DecisionTreeService';
import { AxiosError, AxiosResponse } from "axios";

export class FormUtils {
  static createAxiosResponse(data: any, status: number | null = null): AxiosResponse<any> {
    return {
      config: {},
      data: data,
      headers: {},
      status: status ?? 200,
      statusText: ""
    };
  }

  static createAxiosNotFound(): AxiosError<any> {
    return {
      message: "", name: "", stack: "", toJSON(): object {
        return {};
      },
      config: {},
      response: FormUtils.createAxiosResponse(null, 404),
      isAxiosError: true
    };
  }

  static formatValue(
    t: TFunction,
    form: Form,
    history: number[],
    values: Record<string, any>,
    callback: (id: number, question: FormQuestion, valueFormatted: string) => void
  ) {
    for (let i = 0; i < history.length; i++) {
      const id = history[i];
      const question = form.questions[id];
      let value = values[question.question];

      switch (question.type) {
        case QuestionType.RADIO:
        case QuestionType.MATRIX:
        case QuestionType.RADIO_IMAGE:
          break;
        case QuestionType.NUMBER:
          value = value + " " + question.unit;
          break;
        case QuestionType.BOOL:
          value = value ? "oui" : "non";
          break;
      }

      callback(id, question, value);
    }
  }

  /**
   * Get the values to send to the back based on the currentQuestion
   * @return Record<string, any>
   */
  static getValidValues(questions: FormQuestion[], allValues: Record<string, any>, questionHistory: number[], currentQuestion: number, allowNextQuestion?: boolean) {
    const values: Record<string, any> = {};
    const history = [...questionHistory];

    // Add currentQuestion to history if not present
    if (!history.includes(currentQuestion)) {
      history.push(currentQuestion);
    }

    let i = 0;
    while (i < history.length) {
      const questionId = history[i];

      i++;

      // Don't use the question if it's after the current question
      if (questionId > currentQuestion && !allowNextQuestion) {
        continue;
      }

      const question = questions[questionId];

      // Continue if question was not found
      if (!question || !question?.question) {
        continue;
      }

      const value = allValues[question.question];

      // Continue if the value of the question is undefined. Otherwise decision trees might have an issue
      if (value === undefined) {
        continue;
      }

      values[question.question] = value;
    }

    return values;
  }

  /**
   * Check condition for a question
   */
  static async checkCondition(
    decisionTreeService: DecisionTreeService,
    leftValue: any,
    operator: OperatorType,
    rightValue: any,
    values: Record<string, any>,
    cache: Map<string, any>
  ): Promise<boolean> {
    if (leftValue.question) {
      leftValue = values[leftValue.question];
    } else if (leftValue.decision_tree) {
      if (cache.has(leftValue.decision_tree)) {
        leftValue = cache.get(leftValue.decision_tree);
      } else {
        const decisionTree = leftValue.decision_tree;
        leftValue = await decisionTreeService.computeDecisionTree(
          leftValue.decision_tree,
          values
        );
        cache.set(decisionTree, leftValue);
      }
    }

    switch (operator) {
      case OperatorType.EQUAL:
        return leftValue === rightValue;
      case OperatorType.NOT_EQUAL:
        return leftValue !== rightValue;
      case OperatorType.AND:
        return (
          (await FormUtils.checkCondition(
            decisionTreeService,
            leftValue.lhs,
            leftValue.operator,
            leftValue.rhs,
            values,
            cache
          )) &&
          (await FormUtils.checkCondition(
            decisionTreeService,
            rightValue.lhs,
            rightValue.operator,
            rightValue.rhs,
            values,
            cache
          ))
        );
      case OperatorType.OR:
        return (
          (await FormUtils.checkCondition(
            decisionTreeService,
            leftValue.lhs,
            leftValue.operator,
            leftValue.rhs,
            values,
            cache
          )) ||
          (await FormUtils.checkCondition(
            decisionTreeService,
            rightValue.lhs,
            rightValue.operator,
            rightValue.rhs,
            values,
            cache
          ))
        );
      case OperatorType.GREATER_THAN:
        return leftValue > rightValue;
      case OperatorType.GREATER_THAN_OR_EQUAL_TO:
        return leftValue >= rightValue;
      case OperatorType.LOWER_THAN:
        return leftValue < rightValue;
      case OperatorType.LOWER_THAN_OR_EQUAL_TO:
        return leftValue <= rightValue;
      case OperatorType.IN:
        if (Array.isArray(rightValue) && rightValue) {
          return (rightValue as any[]).includes(leftValue);
        } else if (Array.isArray(leftValue) && leftValue) {
          return (leftValue as any[]).includes(rightValue);
        } else {
          return false;
        }
      case OperatorType.NOT_IN:
        if (Array.isArray(rightValue) && rightValue) {
          return !(rightValue as any[]).includes(leftValue);
        } else if (Array.isArray(leftValue) && leftValue) {
          return !(leftValue as any[]).includes(rightValue);
        } else {
          return true;
        }
    }
    return false;
  }

}
