import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import "./LogoutButton.scss";
import { AuthService } from '../services/AuthService';
import { Button } from 'primereact/button';
import { TFunction } from 'i18next';
import { useHistory } from 'react-router-dom';

interface Props extends WithTranslation {
}

function LogoutButton(props: Props) {
  const authService = new AuthService();
  const history = useHistory();
  const t = props.t as TFunction;

  const handleClick = () => {
    authService.logout();
    history.push("/login");
  }

  return (
    <div className="text-center">
      <Button className="button-logout"
              icon="pi pi-sign-out"
              iconPos="left"
              type="button"
              label={t("UI.BUTTON.LOGOUT")} onClick={() => {
        handleClick();
      }}/>
    </div>
  );
}

export default withTranslation()(LogoutButton);
